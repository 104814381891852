import { useState } from 'react';
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi'; // Importing arrow down and menu icons from react-icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './sidebarStyles.css'; // Import CSS file
import { TbReportSearch } from "react-icons/tb";
import {  FaChartBar, FaRupeeSign  } from 'react-icons/fa';
import { MdAccountBalanceWallet } from "react-icons/md";

const Sidebar1 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State for sidebar
  const [openedSubmenu, setOpenedSubmenu] = useState(null); // State for opened submenu
  const [openedSubSubmenu, setOpenedSubSubmenu] = useState(null); // State for opened submenu
  const menu = JSON.parse(localStorage.getItem("Menu"))

  let sideBarMenu = menu

  const UserData = JSON.parse(localStorage.getItem("userInfo"))
  const logo = UserData[0].Logo
  
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
    // console.log(isSidebarOpen);
  };
  const handleSidebarToggle1 = () => {
    setIsSidebarOpen(isSidebarOpen);
    // console.log(isSidebarOpen);
  };

  const groupedMenu = sideBarMenu.reduce((acc, curr) => {
    if (!acc[curr.FGroup]) {
      acc[curr.FGroup] = [];
    }
    acc[curr.FGroup].push(curr);
    return acc;
  }, {});

  const handleSubmenuToggle = (fGroup) => {
    setOpenedSubmenu(prevState => (prevState === fGroup ? null : fGroup)); // Toggle submenu state
    // setOpenedSubSubmenu(null); // Close subsubmenu when submenu is toggled
    
  };

  const handleSubSubmenuToggle = (subMenuItem) => {
    setOpenedSubSubmenu(prevState => (prevState === subMenuItem.FormKey ? null : subMenuItem.FormKey)); // Toggle subsubmenu state
  };


  
  return (
    <div className={`top-0 left-0 bg-white h-screen inset-0 bg-opacity-100 z-50 overflow-y-auto rounded-lg  ${isSidebarOpen ? '' : 'hidden'}`} id="sidebar-container">
      {/* Sidebar content */}
      <div className="flex justify-center items-center p-2 h-21" onClick={handleSidebarToggle} overflow-y-auto>
        <div className='left-[50%] w-[50%] rounded-lg'>
        <Link to='/dashboard'>
            <img src={logo} alt="Logo" className="p-2 rounded-lg" />
            </Link>
        </div>
        </div>
      <div className="p-2 mt-5 cursor-pointer ">
      {Object.entries(groupedMenu).map(([fGroup, menuItems]) => (
        <ul key={fGroup}>
          <li className="text-black font-bold text-1xl relative bg-white p-1 rounded-lg" onClick={() => handleSubmenuToggle(fGroup)}>
            <button className="text-black ml-2 mt-4 absolute top-0 right-0 transform -translate-y-1/2">
              {openedSubmenu === null || openedSubmenu !== fGroup ? <FiChevronDown /> : <FiChevronUp />}
            </button>
            <span>
                  {fGroup === '2' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <FaRupeeSign />
                    Transaction
                    </div>
                    </>
                  )}
                  {fGroup === '3' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <FaChartBar /> Display
                    </div>
                    </>
                  )}
                  {fGroup === '4' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <TbReportSearch /> Reports
                    </div>
                    </>
                  )}
                  {fGroup === '12' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <MdAccountBalanceWallet /> Accounts
                    </div>
                    </>
                  )}
                </span>
            {openedSubmenu === fGroup && (
              <ul className="mt-1 ml-10">
                {menuItems.map(menuItem => (
                  <li key={menuItem.FormKey} className="text-[#4FA9B7]">
                    {menuItem.SubCategory === 1 ? (
                      <div>
                        <li className='list-roman'>{menuItem.FormName}</li>
                        {openedSubSubmenu === menuItem.FormKey && (
                          <ul>
                            {sideBarMenu
                              .filter(item => item.SubCategory === 0 && item.FormKey.toString().startsWith(menuItem.FormKey.toString()))
                              .map(subMenuItem => (
                                <li key={subMenuItem.FormKey} onClick={handleSidebarToggle1}>
                                <Link to={`/dashboard/${subMenuItem.FormName.replace(/\s+/g, '-').toLowerCase()}`}>{subMenuItem.FormName}</Link>
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <ul className='ml-8 text-black rounded-md p-1' onClick={handleSidebarToggle}>
                              <Link to={`/${menuItem.FormName.replace(/\s+/g, '-').toLowerCase()}`}>
                                  <li key={menuItem.FormKey} className='list-disc'>
                                      {menuItem.FormName}
                                  </li>
                              </Link>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      ))}
      </div>
    </div>
  );
};

export default Sidebar1;
