import React, { useState } from "react";
import axios from "axios"; // Import Axios library
import { useNavigate } from "react-router-dom"; // Import useHistory hook for navigation
import Cookies from "js-cookie";
import { api, api1, api2 } from "../Common/apiURL";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from '../../assets/Logo.png'

const Login = ({ handleLogin }) => {
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userLocations, setUserLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [cName, setCName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [response, setResponse] = useState([]);

  const navigate = useNavigate(); // Initialize useHistory

  const handleSubmit = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    const { userId, password , location } = formData;
    // console.log(formData )
    if (!userId || !password) {
    setShowLoader(false);
      alert("Please enter both User ID and Password");
      return;
    }

    const test = {
      LogID: formData.userId.toLowerCase(),
      LogPwd: formData.password,
      DepoLocation:formData.location
    };

    try {
      const BASE_URL = api2;
      const response = await axios.post(`${BASE_URL}/auth/login`, test);
      console.log(response.data)
      setResponse(response.data)
      // if (response.status >= 400 && response.status < 500) {
      //   // Credentials are incorrect or other client-side error
      //   alert("Invalid credentials. Please try again.");
      //   toast.error('Invalid credentials. Please try again.')
      // } else {
        // Credentials are correct
        const userData = response.data.responseData;
        const matchedDepots = userData.userDepot
          .filter(depot => depot.LogID === userData.connectionDB[0].LogID);

        // Log only the matched LogID
        console.log(matchedDepots);
        const token = response.data.token;
        const menu = response.data.menu;
        
        if (userData.userDepot) {
          localStorage.setItem("userInfo", JSON.stringify(userData.connectionDB));
          localStorage.setItem("Depo", JSON.stringify(userData.userDepot));
          localStorage.setItem("Menu", JSON.stringify(menu));
          
          setIsLoggedIn(true);
          setUserLocations(matchedDepots);
          setCName(userData.connectionDB[0].CName);
          Cookies.set("token", token, { expires: 1 / 24 });
          setTimeout(() => {
            setShowLoader(false);
          }, 1000);
        } else {
          // Display error message for incorrect credentials
          alert("Incorrect credentials");
        }
      // }
    } catch (error) {
      // Handle other errors, such as network issues
      console.error("Error logging in:", error);
      if (error.response && error.response.status === 450) {
        alert("Wrong Credentials");
      } else {
        alert("Internal Server Error. Please try again later.");
      }
    
      setShowLoader(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleClick = async() => {
    setShowLoader(true)
    const isLoggedIn = true; // Set to true for successful login
    if (!selectedLocation) {
      alert("Please select a location");
      return;
    }
    let LogId;
    const Depo = JSON.parse(localStorage.getItem("Depo"));
    console.log(Depo)
    const selectedDP = Depo.find(item => item.DpName === selectedLocation);

    console.log(selectedDP)

    const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
    LogId = itemFromLocalStorage[0].LogID
    
    const token = Cookies.get("token");
    //   console.log(token)
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    };
    const requestBody = {
      LogID: LogId,
      Depo: selectedDP.DPCode
    };
    const BASE_URL = api2;
    //   const BASE_URL = api1
    const response = await axios.post(
      `${BASE_URL}/auth/ip`,
      requestBody,
      config
    );
    const LogNo = response.data.LogNo
    const LogValue = localStorage.setItem("LogValue", LogNo);
    navigate("/dashboard");

    localStorage.setItem("selectedLocation", selectedLocation);
    localStorage.setItem("cName", cName);
    localStorage.setItem("isLoggedIn", isLoggedIn);
    handleLogin();
    setShowLoader(false)
  };

  return (
    <div className=" h-screen flex justify-center items-center p-2">
      {showLoader && <Loader />}
      <div className="mx-auto w-full md:w-1/2 lg:w-1/2 px-4 py-16 sm:px-6 lg:px-8 shadow-md rounded-xl ">
      <img src={logo} className='h-[40%] w-[90%] lg:h-[40%] lg:w-[60%] mx-auto my-auto rounded-lg mb-10 shadow-2xl'/>

        <div className="mx-auto w-full ">
          {isLoggedIn && userLocations && userLocations.length > 0 ? (
            <h1 className="text-center text-2xl font-bold text-[#4FA9B7] sm:text-3xl">
              {cName}
            </h1>
          ) : (
            <h1 className="text-center text-2xl font-bold text-black sm:text-3xl">
              LOGIN
            </h1>
          )}
          <form
            onSubmit={handleSubmit}
            className="mb-0 mt-2 space-y-4 rounded-lg p-4 text-black shadow-2xl bg-white sm:p-6 lg:p-8"
          >
            <div>
              <label htmlFor="userId" className="text-lg text-black font-bold">
                USER ID
              </label>
              <input
                type="text"
                className="w-full rounded-lg border-[#4FA9A7] p-4 text-md shadow-lg"
                placeholder="Enter UserID"
                name="userId"
                value={formData.userId}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="text-lg text-black font-bold"
              >
                PASSWORD
              </label>
              <input
                type="password"
                className="w-full rounded-lg border-[#4FA9A7] p-4 text-md shadow-lg"
                placeholder="Enter password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            {isLoggedIn && userLocations && userLocations.length > 0 && (
              <div>
                <label
                  htmlFor="location"
                  className="text-lg font-bold text-black"
                >
                  BRANCH
                </label>
                <select
                  id="location"
                  className="w-full rounded-lg border-gray-200 p-4 text-md shadow-lg"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                >
                  <option value="">Select a branch</option>
                  {userLocations.map((location, index) => (
                    <option key={index} value={location.DpName}>
                      {location.DpName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <button
              type="button"
              className="block w-full rounded-lg bg-[#4FA9B7] hover:bg-[#4FA9D7] px-5 py-3 text-lg shadow-lg font-medium text-white"
              onClick={selectedLocation ? handleClick : handleSubmit}
            >
              {selectedLocation ? "Login" : "Verify"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
