import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './datagrid.css'; 


const DataTable = ({ rows, columns }) => {

  const getRowClassName = (params) => {
    // console.log(params)
    if (
      params.row.Particulars === 'Grand Total' ||
      params.row.LName === 'Opening Balance' || 
      params.row.LName === 'Grand Total' || 
      params.row.LName === 'Total' ||
      params.row.PartyName === 'Grand Total ' ||
      params.row.itemGroup === 'Grand Total'
    ) {
      return 'custom-grand-total-row bg-white text-red-600 font-bold';
    }
    return params.id % 2 === 0 ? 'custom-even-row' : 'custom-odd-row';
  };
  
  const classes = {
    row: 'custom-row',
    cell: 'custom-cell',
    headerCell: 'custom-header-cell',
  };

  const formattedRows = rows.map((row, index) => ({ ...row, id: index + 1 }));

  if (!Array.isArray(rows)) {
    console.error('Rows data is not an array or is undefined');
    return null; 
  }
  const NoRowsOverlay = () => null;

  return (
    <div className="flex flex-col h-screen">
    {/* Render sidebar from another component */}
    <div className="flex-grow p-3">
      <div className="h-[600px] z-0 custom-scrollbar ">
        <DataGrid
          rows={formattedRows}
          columns={columns}
          pageSize={5}
          getRowClassName={getRowClassName}
          components={{
            NoRowsOverlay: NoRowsOverlay
          }}
        />
      </div>
    </div>
  </div>

  );
};

export default DataTable;
