// Dashboard.jsx
import React from 'react';
import Content from './Content';

const Dashboard = () => {
  return (
    <Content/>
  );
};

export default Dashboard;
