import React, { useEffect, useState } from "react";
import CommonButton from "../Common/FindButton";
import DropDown from "../Common/Dropdown";
import CustomDatePicker from "../Common/DatePicker";
import DataTable from "../Common/DataTable";
import Loader from "../Common/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import { api1, api2 } from "../Common/apiURL";
import { useTable, useSortBy, usePagination } from "react-table";
import DynamicTable from "../Common/NewDataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StockSummary = () => {
  const [dropdown2Value, setDropdown2Value] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [allItems, setAllItems] = useState([]);

  // const columns = [
  //   { field: 'id', headerName: 'S No', width: 100, align: 'center' , headerAlign: 'center' },
  //   { field: 'IName', headerName: 'Item name', width: 350 , align: 'left' , headerAlign: 'center' },
  //   { field: 'BalQty', headerName: 'Bal Qty', width: 150 ,align: 'right' , headerAlign: 'center',
  //     valueFormatter: (params) => {
  //         const value = parseFloat(params);
  //         return isNaN(value) ? '' : value.toFixed(2);
  //     }
  //   },
  //   { field: 'UName', headerName: 'Unit ', width: 50 ,align: 'left' , headerAlign: 'center' },
  //   { field: 'Price', headerName: 'Price', width: 100 , align: 'right' , headerAlign: 'center',
  //     valueFormatter: (params) => {
  //         const value = parseFloat(params);
  //         return isNaN(value) ? '' : value.toFixed(2);
  //     }
  //   },
  // ];

  const columns = [
    {
      Header: "S No",
      accessor: "id",
      align: "left", // Align header text to the left
      headerAlign: "left", // Align header cell content to the left
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>, // Align cell content to the right
    },
    {
      Header: "Item name",
      accessor: "IName",
      Cell: ({ value }) => <div style={{ width: "250px" }}>{value}</div>,
    },
    {
      Header: "Bal Qty",
      accessor: "BalQty",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),
    },
    { Header: "Unit", accessor: "UName" },
    {
      Header: "Price",
      accessor: "Price",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = Cookies.get("token");
      //   console.log(token)
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      let LogId;
      let DPCode;
      const Depo = JSON.parse(localStorage.getItem("Depo"));
      const selectedLocation = localStorage.getItem("selectedLocation");
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)
      const requestBody = {
        UserName: LogId,
      };
      const BASE_URL = api2;
      //   const BASE_URL = api1
      const response = await axios.post(
        `${BASE_URL}/itemLists/get-items`,
        requestBody,
        config
      );
      const AllItems = response.data.AllItems;
      // console.log(AllItems);
      setAllItems(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleSearch = async () => {
    setShowLoader(true);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;

    let LogId;
    let DPCode;
    const targetPage = JSON.parse(localStorage.getItem("Menu")).find(
      (item) => item.FormName === "Stock Summary"
    );
    const Depo = JSON.parse(localStorage.getItem("Depo"));
    const selectedLocation = localStorage.getItem("selectedLocation");

    if (targetPage) {
      const FormKey = targetPage.FormKey;
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        };

        const requestBody = {
          UserName: LogId,
          DepotCode: DPCode,
          StartDate: formattedStartDate,
          IGName: dropdown2Value === "all" ? "" : dropdown2Value,
          FormKey: FormKey,
        };
        const BASE_URL = api2;
        const response = await axios.post(
          `${BASE_URL}/itemLists/get-items-data`,
          requestBody,
          config
        );
        const AllItems = response.data.AllItemsData;
        const formattedRows = AllItems.map((item, index) => ({
          id: index + 1,
          IGName: item.IGName,
          IName: item.IName,
          BalQty: item.BalQty,
          UName: item.UName,
          Price: item.Price,
        }));
        toast.success("Item list retrieved successfully.");
        setRows(formattedRows);
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Failed to fetch data. Please try again later.");
      } finally {
        setShowLoader(false);
      }
    }

    setShowLoader(false);
  };

  const handleDropdownChange = (e) => {
    setDropdown2Value(e.target.value);
    setRows([]);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setRows([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      handleSearch();
    }
  };

  const items = allItems.AllItems
    ? allItems.AllItems.map((item) => ({
        value: item.IGname,
        label: item.IGname,
      }))
    : [];
  items.unshift({ value: "all", label: "All" });

  // console.log(rows)
  return (
    <div className="">
      {showLoader && <Loader />}
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        STOCK SUMMARY
      </h1>
      <div onKeyDown={handleKeyDown}>
        <div className="flex flex-col lg:flex-row items-start justify-start p-2 space-y-2 lg:space-y-0 lg:space-x-1">
          <DropDown
            id="dropdown2"
            value={dropdown2Value}
            onChange={handleDropdownChange}
            options={items}
            label="Item Group"
          />
          <CustomDatePicker
            id="startDate"
            selected={startDate}
            onChange={handleStartDateChange}
            className="p-1 border rounded-md outline-none w-full"
            label="Start Date"
          />
          <CommonButton onClick={handleSearch} />
        </div>
      </div>
      <DynamicTable columns={columns} rows={rows} />
    </div>
  );
};

export default StockSummary;
