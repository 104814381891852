import React, { useEffect, useState } from "react";
import DropDown from "../Common/Dropdown";
import axios from "axios";
import Cookies from "js-cookie";
import { api, api1, api2, api3 } from "../Common/apiURL";
import CommonButton from "../Common/FindButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDatePicker from "../Common/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../Common/Loader";
import { FaEdit } from "react-icons/fa";
import DataTable from "../Common/DataTable";
import DynamicTable from "../Common/NewDataTable";
import BillModal from "./BillModal";
import { useNavigate } from "react-router-dom";

const BillPermission = () => {
  const [dropdown1Value, setDropdown1Value] = useState("");
  const [partyNames, setPartyNames] = useState([]);
  const [days, setDays] = useState("1");
  const [date, setDate] = useState(new Date());
  const [allowedBy, setAllowedBy] = useState("");
  const [billingAmount, setBillingAmount] = useState("");
  const [remark1, setRemark1] = useState("");
  const [remark2, setRemark2] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false); // New state to force data refresh
  const navigate = useNavigate();




  const openModal = (row) => {
    // console.log(row)
    setSelectedRow(row);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };
  

  let Depo = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const BASE_URL = api2;
  var LogId = null;
  var DPCode = null;

  const fetchData = async () => {
    try {
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const requestBody = {
        Username: LogId,
        FormKey: 0,
        DPCode: DPCode,
        AgentName: "",
      };


      const BASE_URL1 = api2;
      const response = await axios.post(
        `${BASE_URL1}/parties`,
        requestBody,
        config
      );
      const partyNamesData = response.data.resultWebPartyList.map(
        (row) => row.LName
      );
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      setPartyNames(uniquePartyNames);
    } catch (error) {
      // if(error.response.status){
      //   alert('Please Login Again!!!')
      //   navigate('/')
      // }
      console.error("Error fetching data:", error);
    }
  };

  const fetchBillData = async() =>{
    try {
      const requestBody = {
        FormKey: 0
      };
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      // Convert this in future from get to post
      const BASE_URL = api2;
      const response1 = await axios.post(
        `${BASE_URL}/billPermission/get-bills`,requestBody,config
      );
      // console.log(response1);
      const rowsWithId = response1.data.AllBills.map((row, index) => ({
        ...row,
        id: index + 1, // You can use any unique identifier here
      }));
      setRows(rowsWithId);
    } catch (error) {
      console.error('Error in Bill Data ')
    }
  }

  const handleDropdown1Change = (e) => {
    setDropdown1Value(e.target.value);
  };
  console.log(partyNames)
  const parties = [
    { value: "", label: "Select Party Name" },
    ...partyNames.map((partyName, index) => ({
      value: partyName,
      label: partyName,
    })),
  ];

  const handleSubmit = async () => {
    setShowLoader(true);
    if (!dropdown1Value || !allowedBy || !days) {
      toast.error("Please fill in all required fields.");
      setShowLoader(false);
      return;
    }
    Depo.forEach((depo) => {
      if (depo.DpName == selectedLocation) {
        DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
      }
    });
    const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
    LogId = itemFromLocalStorage[0].LogID
    console.log(LogId)
    const formattedDate = `${parsedDate.getFullYear()}${(
      parsedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${parsedDate.getDate().toString().padStart(2, "0")}`;
    const RefCnt = 1;
    const DocID = "";
    const formData = {
      LogId,
      id: rows.length + 1,
      partyName: dropdown1Value,
      date: formattedDate,
      days,
      allowedBy,
      billingAmount,
      remark1,
      remark2,
      endDate,
      RefCnt,
      DocID,
      DPCode,
    };
    const form = {
      UserName: LogId,
      DpCode: DPCode,
      PartyName: dropdown1Value,
      VDate: formattedDate,
      ForDays: days,
      AllowedBy: allowedBy,
      Amount: billingAmount,
      Remark1: remark1,
      Remark2: remark2,
      RefCnt,
      DocID,
    };
    console.log("Form Data:", form);
    const token = Cookies.get("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    };
    const response = await axios.post(
      `${BASE_URL}/billPermission`,
      form,
      config
    );
    const updatedRows = [...rows, formData];
    setRows(updatedRows);
    toast.success("Form submitted successfully!");
    // Reset all fields to initial state
    handleClear();
    setForceRefresh(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  };

  const handleClear = () => {
    setDropdown1Value("");
    setDate(
      new Date().toLocaleDateString("en-GB").split("/").reverse().join("/")
    );
    setDays("0");
    setAllowedBy("");
    setBillingAmount("");
    setRemark1("");
    setRemark2("");
    setEndDate("");
    // toast.success("Clear Form Successfully");
  };

  useEffect(() => {
    const startDate = new Date(date);
    // console.log(startDate)
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + parseInt(days - 1));
    const formattedEndDate = `${endDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${endDate.getFullYear()}`;
    setEndDate(formattedEndDate);
  }, [date, days]);

  const parsedDate = new Date(date);
  const formattedDate = `${parsedDate.getDate().toString().padStart(2, "0")}/${(
    parsedDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${parsedDate.getFullYear()}`;
  const columns = [
    {
      Header: "S No",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center", width: "50px" }}>
          {row.index + 1}
        </div>
      ),
    },
    {
      accessor: "DocID",
      Header: "Doc ID",
      Cell: ({ value }) => (
        <div style={{ width: "150px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "LName",
      Header: "Party Name",
      width: 200,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "250px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "VDate",
      Header: "Start Date",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          // Return empty string or any other default value if params.value is not a valid date
          return "";
        }
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      accessor: "ForDays",
      Header: "Days",
      width: 50,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "50px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "EndDate",
      Header: "End Date",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          // Return empty string or any other default value if params.value is not a valid date
          return "";
        }
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      accessor: "AllowedBy",
      Header: "Allowed By",
      width: 150,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "150px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "Amount",
      Header: "Billing Amount",
      width: 150,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "150px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "Remark1",
      Header: "Remark 1",
      width: 100,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "Remark2",
      Header: "Remark 2",
      width: 100,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "actions",
      Header: "Actions",
      Cell: (params) =>
        params.row.itemGroup !== "Grand Total" ? (
          <div className="flex mt-2">
            {/* Edit icon */}
            <FaEdit
              size={25}
              style={{ cursor: "pointer", marginRight: "10px", color: "blue" }}
              onClick={() => openModal(params.row.original)}
            />
          </div>
        ) : null,
    },
  ];

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day}/${month}/${year}`;
  };

  const transformData = (updatedData) => {
    const startDate = formatDate(updatedData.VDate);
    const days = parseInt(updatedData.ForDays); // Parsing number of days from string to integer
    const endDate = new Date(startDate); // Creating new Date object for end date
    
    // Adding days to the start date to get end date
    endDate.setDate(endDate.getDate() + days - 2); // Subtracting 1 because end date is inclusive
    
    console.log(endDate)
    return {
      AllowedBy: updatedData.AllowedBy || '',
      Amount: updatedData.Amount || 0,
      DocID: updatedData.DocID || '',
      ForDays: updatedData.ForDays || '',
      LName: updatedData.PartyName || '',
      RefCnt: updatedData.RefCnt || 0,
      Remark1: updatedData.Remark1 || '',
      Remark2: updatedData.Remark2 || '',
      VDate: startDate || '',
      EndDate: endDate
    };
  };

  // Function to update the data in the DataTable after an update
  const updateRowData = (updatedRow) => {
    const updatedData = transformData(updatedRow.data);
    console.log(updatedRow)
    console.log(updatedData)
    const updatedRows = rows.map((row) => {
      if (row.DocID === updatedRow.data.DocID) {
        return updatedData ;
      } else {
        return row; // Exclude the row from the array
      }
    })
    
    console.log(updatedRows);
    setRows(updatedRows);
  };
  

  useEffect(() => {
    fetchData();
    fetchBillData()
    // updateRowData()
    // setForceRefresh(false); // Reset forceRefresh after data fetch
  }, []);

  console.log(isModalOpen)
  return (
    <div>
      {showLoader && <Loader />}
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        BILL PERMISSION
      </h1>
      <div className="mb-4 p-1">
        <div className="grid grid-cols-2 gap-4 p-1 mt-1 rounded-lg">
          <div className="mt-[-4px]">
            <DropDown
              id="dropdown1"
              value={dropdown1Value}
              onChange={handleDropdown1Change}
              options={parties}
              label="Party Name"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <CustomDatePicker
                id="date"
                selected={date}
                onChange={(newDate) => setDate(newDate)}
                className="border border-gray-300 rounded-md px-2 py-1 w-full"
                label="Date"
              />
            </div>

            <div>
              <label htmlFor="days" className="block">
                For Days:
              </label>
              <input
                type="number"
                id="days"
                value={days}
                onChange={(e) => setDays(e.target.value)}
                className="border border-gray-300 rounded-md px-2 py-1 w-full"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-1 p-1">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="allowedBy" className="block">
                Allowed By:
              </label>
              <input
                type="text"
                id="allowedBy"
                value={allowedBy}
                onChange={(e) => setAllowedBy(e.target.value)}
                className="border border-gray-300 rounded-md px-2 py-1 w-full"
              />
            </div>
            <div>
              <label htmlFor="billingAmount" className="block">
                Amount:
              </label>
              <input
                type="text"
                id="billingAmount"
                value={billingAmount}
                onChange={(e) => setBillingAmount(e.target.value)}
                className="border border-gray-300 rounded-md px-2 py-1 w-full text-right"
              />
            </div>
          </div>
          <div>
            <h1 className="font-bold text-xl text-black p-2 w-full text-center mt-3">
              {days > 0 && formattedDate && endDate
                ? `${formattedDate} to ${endDate}`
                : `${formattedDate} to DD/MM/YYYY`}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-1 p-1">
          <div>
            <label htmlFor="remark1" className="block">
              Remark 1:
            </label>
            <input
              type="text"
              id="remark1"
              value={remark1}
              onChange={(e) => setRemark1(e.target.value)}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
          <div>
            <label htmlFor="remark2" className="block">
              Remark 2:
            </label>
            <input
              type="text"
              id="remark2"
              value={remark2}
              onChange={(e) => setRemark2(e.target.value)}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
          <div className="w-full">
            <button
              onClick={handleClear}
              className="bg-gray-600 hover:bg-red-700 text-white w-full font-bold py-4 px-4 rounded"
            >
              Clear
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 p-1 mt-1">
          <div className="w-full">
            <button
              onClick={handleSubmit}
              className="bg-[#4FA9A7] hover:bg-[#4FA9B7] text-white w-full font-bold py-4 px-4 rounded"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <DynamicTable columns={columns} rows={rows} />
      {isModalOpen === true && (
        <BillModal isOpen={isModalOpen} onClose={closeModal} rowData={selectedRow} updateRowData={updateRowData}  />
      )}
    </div>
  );
};

export default BillPermission;
