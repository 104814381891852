import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from "@mui/x-data-grid";
import { MdVisibility, MdGetApp } from "react-icons/md";
import { MdClose } from "react-icons/md";
import jsPDF from "jspdf"; // Import jsPDF library
import { saveAs } from "file-saver";
import axios from "axios"; // Import Axios
import Cookies from "js-cookie";
import "jspdf-autotable";
import { FiSearch } from "react-icons/fi";
import DownloadData from "../Common/DownloadData";
import CommonButton from "../Common/FindButton";
import DropDown from "../Common/Dropdown";
import CustomDatePicker from "../Common/DatePicker";
import DataTable from "../Common/DataTable";
import Loader from "../Common/Loader";
import * as XLSX from "xlsx"; // Import xlsx library
import { api1, api2 } from "../Common/apiURL";
import DynamicTable from "../Common/NewDataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const SaleRegister1 = () => {
  // Dummy data for dropdowns
  const [rows, setRows] = useState([]);
  const [dropdown1Value, setDropdown1Value] = useState("all");
  const [dropdown2Value, setDropdown2Value] = useState("all");
  const [totalTaxableValue, setTotalTaxableValue] = useState(0);
  const [totalBillAmount, setTotalBillAmount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterText, setFilterText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [agentNames, setAgentNames] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depo = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  let targetRoute = "Sales Register";
  const BASE_URL = api2;
  const navigate = useNavigate();
 
  useEffect(() => {
    fetchUserData();
  }, [dropdown1Value]);

  const fetchUserData = async () => {
    try {
      const targetPage = menuValue.find(
        (item) => item.FormName === targetRoute
      );
      var FormKey = targetPage ? targetPage.FormKey : null;
      // console.log('FormKey of the target page:', FormKey);

      var LogId = null; // Initialize LogId
      var DPCode = null; // Initialize DPCode

      // Map through Depo and check selectedLocation
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)
      console.log(itemFromLocalStorage)
      // console.log(dropdown1Value)
      // Create the request body object with updated values
      const requestBody = { UserName: LogId, FormKey: FormKey, DPCode: DPCode };
      const token = Cookies.get("token"); // Retrieve token from cookies
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agents`,
        requestBody,
        config
      );
      // console.log(response.data)
      if (dropdown1Value === "all") {
        const requestBody1 = {
          Username: LogId,
          FormKey: FormKey,
          DPCode: DPCode,
          AgentName: "",
        };
        const response1 = await axios.post(
          `${BASE_URL}/parties`,
          requestBody1,
          config
        );
        const partyNamesData = response1.data.resultWebPartyList.map(
          (row) => row.LName
        );
        const uniquePartyNames = Array.from(new Set(partyNamesData));
        setPartyNames(uniquePartyNames);
        // console.log(response1.data);
        // Handle the response from the second API call as needed
      } else {
        const requestBody1 = {
          Username: LogId,
          FormKey: FormKey,
          DPCode: DPCode,
          AgentName: dropdown1Value,
        };
        const response1 = await axios.post(
          `${BASE_URL}/parties`,
          requestBody1,
          config
        );
        const partyNamesData = response1.data.resultWebPartyList.map(
          (row) => row.LName
        );
        const uniquePartyNames = Array.from(new Set(partyNamesData));
        setPartyNames(uniquePartyNames);
        // console.log(response1.data);
      }
      // Agent Names
      const agentNamesData = response.data.resultWebAgentList.map(
        (row) => row.Aname
      );
      const uniqueAgentNames = Array.from(new Set(agentNamesData));
      // // Party Names
      // const partyNamesData = response.data.resultWebPartyList.map((row) => row.LName);
      // const uniquePartyNames = Array.from(new Set(partyNamesData));
      // Total

      setAgentNames(uniqueAgentNames);
      // setPartyNames(uniquePartyNames);
      // setRows(userData);
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error fetching user data:", error);
    }
  };

  // console.log(rows)

  const columns = [
    {
      Header: "S No",
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center", width: "50px" }}>
          {row.index + 1}
        </div>
      ),
    },
    {
      accessor: "BillRmk",
      Header: "Bill No",
      width: 130,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "120px" }}>{value}</div>,
    },
    {
      accessor: "BDate",
      Header: "Bill Date",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
    },
    {
      accessor: "Aname",
      Header: "Agent Name",
      width: 130,
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "130px" }}>{value}</div>,
    },
    {
      accessor: "LName",
      Header: "Party Name",
      width: 250,
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "250px" }}>{value}</div>,
    },
    {
      accessor: "SaleAmt",
      Header: "Taxable Value",
      width: 130,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "130px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "BillAmt",
      Header: "Bill Value",
      width: 130,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "130px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "Tname",
      Header: "Transport",
      width: 150,
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "160px" }}>{value}</div>,
    },
    {
      accessor: "Package",
      Header: "Package",
      width: 70,
      align: "center",
      headerAlign: "center",
    },
    {
      accessor: "PDFFileName",
      Header: "Download",
      width: 130,
      Cell: (params) => (
        (
          <div className="flex items-center">
            <MdGetApp
              size={25}
              onClick={
                params.row.values.PDFFileName === ""
                  ? null
                  : () => handleDownload(params.row.values)
              }
              className={`cursor-pointer text-${
                params.row.values.PDFFileName == "" ? "red" : "blue"
              }-600 ${
                params.row.values.PDFFileName == ""
                  ? "red"
                  : "hover:text-green-600"
              }  m-2 mt-3`}
            />
          </div>
        )
      ),
    },
  ];

  useState(() => {
    let taxableValueTotal = 0;
    let billAmountTotal = 0;
    rows.forEach((row) => {
      taxableValueTotal += row.SaleAmt;
      billAmountTotal += row.BillAmt;
    });
    setTotalTaxableValue(taxableValueTotal);
    setTotalBillAmount(billAmountTotal);
  }, []);

  const handleSearch = async () => {
    setShowLoader(true);

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;

    const endDateYear = endDate.getFullYear();
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
    const endDateDay = String(endDate.getDate()).padStart(2, "0");
    const formattedEndDate = `${endDateYear}${endDateMonth}${endDateDay}`;

    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      // Your API endpoint URL
      const apiUrl = `${BASE_URL}/users`;
      var LogId = null; // Initialize LogId
      var DPCode = null; // Initialize DPCode

      // Map through Depo and check selectedLocation
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)
      // Create the request payload
      const requestData = {
        agentName: dropdown1Value === "all" ? "" : dropdown1Value,
        partyName: dropdown2Value === "all" ? "" : dropdown2Value,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        Username: LogId,
        DPCode: DPCode
      };

      // Axios POST request
      const response = await axios.post(apiUrl, requestData, config);

      const userData = response.data.resultSalesReg.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      const totalTaxableValue = response.data.resultSalesReg
        .reduce((acc, row) => acc + row.SaleAmt, 0)
        .toFixed(2);
      const totalBillValue = response.data.resultSalesReg
        .reduce((acc, row) => acc + row.BillAmt, 0)
        .toFixed(2);

      // console.log(response.data);
      setTotalTaxableValue(totalTaxableValue);
      setTotalBillAmount(totalBillValue);
      //  console.log(userData)
      setFiltered(userData);

      toast.success("Sales Register retrieved successfully.");
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error:", error.message);
    } finally {
      setShowLoader(false);
    }
  };
  // console.log('Filtered Data',filtered)
  // console.log('All Data',rows)

  const handleDownload = (row) => {
    // Check if PDFFileName exists
    if (row.PDFFileName) {
      // Open the PDF file in a new tab
      window.open(row.PDFFileName, "_blank");
    } else {
      // Handle case where PDFFileName is not available
      console.error("PDF file not found");
      // Optionally, you can display a message to the user or perform any other action
    }
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const filteredRows = rows.filter((row) =>
    row.LName.toLowerCase().includes(filterText.toLowerCase())
  );

  const formatDataForDownload = () => {
    // Extract data from rows and columns
    const headers = columns
      .filter((column) => column.field !== "PDFFileName") // Exclude the "Download" column
      .map((column) => column.Header);
    const data = filteredRows.map((row) =>
      columns
        .filter((column) => column.field !== "PDFFileName") // Exclude the "Download" column
        .map((column) => row[column.field])
        .join(",")
    );

    // Combine headers and data into a single CSV string
    const csv = [headers.join(","), ...data].join("\n");

    return csv;
  };

  const handleDownloadAsExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Sales_Register");
    XLSX.writeFile(wb, "Sales_Register.xlsx");
  };

  const handleDownloadAsPDF = () => {
    const headers = columns
      .filter((column) => column.field !== "PDFFileName") // Exclude the "Download" column
      .map((column) => column.Header);

    let data = filtered.map((row) =>
      columns
        .filter((column) => column.field !== "PDFFileName") // Exclude the "Download" column
        .map((column) => row[column.field])
    );

    // Include the grand total row directly
    data.push([
      grandTotalRow.id,
      grandTotalRow.BillRmk,
      grandTotalRow.BDate,
      grandTotalRow.Aname,
      grandTotalRow.LName,
      parseFloat(grandTotalRow.SaleAmt).toFixed(2), // Ensure SaleAmt is formatted as a number with 2 decimal places
      parseFloat(grandTotalRow.BillAmt).toFixed(2), // Ensure BillAmt is formatted as a number with 2 decimal places
      grandTotalRow.Tname,
      grandTotalRow.Package,
    ]);

    // Create a new jsPDF instance
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Set the document properties
    doc.setProperties({
      title: "Data Report",
      subject: "Data Export",
      author: "Your Name",
      keywords: "data, report, export",
    });

    const companyName = localStorage.getItem("cName");
    const companyLocation = localStorage.getItem("selectedLocation");

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${day}-${month}-${year}`;

    const endDateYear = endDate.getFullYear();
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const endDateDay = String(endDate.getDate()).padStart(2, "0");

    // Concatenate the components in DD-MM-YYYY format for the endDate
    const formattedEndDate = `${endDateDay}-${endDateMonth}-${endDateYear}`;

    const center = doc.internal.pageSize.width / 2;
    doc.setFontSize(14);
    doc.text(`${companyName} , ${companyLocation}`, center, 7, {
      align: "center",
    });
    // Add the date range below Alifa International, Mumbai
    doc.setFontSize(10);
    doc.text(
      `Date : ${formattedStartDate} to ${formattedEndDate}`,
      center,
      12,
      { align: "center" }
    );
    // Add a line below the date range
    doc.setLineWidth(0.5);
    doc.line(center - 30, 22, center + 30, 22);

    // Add the table to the PDF document
    doc.autoTable({
      head: [headers],
      body: data,
    });

    // Save the PDF document
    doc.save("Sales_Register.pdf");
  };

  const handleSearchByCompanyName = () => {
    const filteredData = filtered.filter((row) =>
      row.LName.toLowerCase().includes(filterText.toLowerCase())
    );
    setFiltered(filteredData);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent default form submission
      handleSearch(); // Trigger search when Enter key is pressed
    }
  };

  const agents = [
    { value: "All", label: "All" },
    ...agentNames.map((agentName, index) => ({
      value: agentName,
      label: agentName,
    })),
  ];

  const parties = [
    { value: "All", label: "All" },
    ...partyNames.map((partyName, index) => ({
      value: partyName,
      label: partyName,
    })),
  ];

  const handleDropdownChange = (e) => {
    setFiltered([]);
    setTotalBillAmount(0);
    setTotalTaxableValue(0);
    setDropdown2Value(e.target.value);
  };
  const handleDropdownChange1 = (e) => {
    setFiltered([]);
    setTotalBillAmount(0);
    setTotalTaxableValue(0);
    setDropdown1Value(e.target.value);
    // console.log("Dropdown 1 Value:", e.target.value);
  };

  const grandTotalRow = {
    BillRmk: "",
    BDate: "",
    Aname: "",
    LName: "Grand Total",
    SaleAmt: totalTaxableValue,
    BillAmt: totalBillAmount,
    Tname: "",
  };

  const handleStartDateChange = (date) => {
    setFiltered([]);
    setTotalBillAmount(0);
    setTotalTaxableValue(0);
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setFiltered([]);
    setTotalBillAmount(0);
    setTotalTaxableValue(0);
    setEndDate(date);
  };
  let finalRows;
  if (totalBillAmount == 0) {
    finalRows = [...filtered];
  } else {
    finalRows = [...filtered];
  }

  return (
    <>
      <div className="">
        {showLoader && <Loader />}
        <h1 className="font-bold text-xl text-black p-2 w-full text-center">
          SALES REGISTER
        </h1>
        <div onKeyDown={handleKeyDown}>
          <div className="flex flex-col lg:flex-row items-start justify-start p-2 space-y-4 lg:space-y-0 lg:space-x-4">
            {/* Dropdowns */}
            <div className="flex flex-col w-full lg:flex-row items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
              <DropDown
                id="dropdown1"
                value={dropdown1Value}
                onChange={handleDropdownChange1}
                options={agents}
                label="Agent Name"
              />

              <DropDown
                id="dropdown2"
                value={dropdown2Value}
                onChange={handleDropdownChange}
                options={parties}
                label="Party Name"
              />
            </div>

            {/* Date picker */}
            <div className="flex flex-row space-x-1 w-full">
              {/* Start Date Picker */}
              <CustomDatePicker
                id="startDate"
                selected={startDate}
                onChange={handleStartDateChange}
                className="p-1 border rounded-md outline-none w-full"
                label="Start Date"
              />

              {/* End Date Picker */}
              <CustomDatePicker
                id="endDate"
                selected={endDate}
                onChange={handleEndDateChange}
                className="p-1 border rounded-md outline-none w-full"
                label="End Date"
              />
            </div>
            <CommonButton onClick={handleSearch} />
          </div>
        </div>
      </div>
      {/* <DataTable rows={finalRows} columns={columns} /> */}
      <DynamicTable columns={columns} rows={finalRows} />
    </>
  );
};

export default SaleRegister1;
