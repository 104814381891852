import React from 'react';
import { MdGetApp } from 'react-icons/md';

const DownloadData = ({ onExcelClick, onPdfClick }) => {
    return (
        <div className='p-2 w-full flex gap-2'>
            <button
                onClick={onPdfClick}
                className="font-semibold text-white p-1 rounded-md bg-[#4FA9A7] w-full"
            >
                <MdGetApp size={20} className="m-auto text-red-600 hover:text-white-100" /> 
                Download Pdf
            </button>
            
        </div>
    );
};

export default DownloadData;
