import React, { useEffect, useState } from "react";
import DownloadData from "../Common/DownloadData";
import CommonButton from "../Common/FindButton";
import DropDown from "../Common/Dropdown";
import DataTable from "../Common/DataTable";
import Loader from "../Common/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx"; // Import xlsx library
import axios from "axios"; // Import Axios
import Cookies from "js-cookie";
import { api1, api2 } from "../Common/apiURL";
import DynamicTable from "../Common/NewDataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/Logo.png'

const CreditorsReceivableBills = () => {
  const [dropdown1Value, setDropdown1Value] = useState("all");
  const [dropdown2Value, setDropdown2Value] = useState("AgentWise");
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [agentNames, setAgentNames] = useState([]);
  const [agentPartyy, setAgentPartyy] = useState([]);

  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depo = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  let targetRoute = "Bills Outstanding [Payable]";
  const navigate = useNavigate();

  var FormKey;
  let outStandingIndex = 1;

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const targetPage = menuValue.find(
        (item) => item.FormName === targetRoute
      );
      var FormKey = targetPage ? targetPage.FormKey : null;
      // console.log('FormKey of the target page:', FormKey);

      var LogId = null; // Initialize LogId
      var DPCode = null; // Initialize DPCode

      // Map through Depo and check selectedLocation
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)
      // Create the request body object with updated values
      const token = Cookies.get("token"); // Retrieve token from cookies
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const BASE_URL = api2;
      const requestBody = { UserName: LogId, FormKey: FormKey, DPCode: DPCode };
      const response = await axios.post(
        `${BASE_URL}/agents`,
        requestBody,
        config
      );
      const Agents = response.data.resultWebAgentList;
      console.log("Agent List", Agents, requestBody);
      // setAgentNames(Agents)

      const requestBody1 = {
        UserName: LogId,
        FormKey: FormKey,
        DPCode: DPCode,
        AgentName: "",
      };
      const response1 = await axios.post(
        `${BASE_URL}/parties`,
        requestBody1,
        config
      );
      const Parties = response1.data.resultWebPartyList;
      console.log("Party List", Parties, requestBody);
      setPartyNames(Parties);
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error fetching user data:", error);
    }
  };

  const parties = [
    { id: 0, value: "AgentWise", label: "AgentWise" },
    { id: 1, value: "PartyWise", label: "PartyWise" },
  ];
  useEffect(() => {
    if (dropdown2Value === "AgentWise") {
      // Map agent names to the desired format
      const formattedAgentNames = agentNames.map((agent) => ({
        value: agent.Aname,
        label: agent.Aname,
      }));
      // Add the 'All' option
      const agentPartyOptions = [
        { value: "all", label: "All" },
        ...formattedAgentNames,
      ];
      setAgentPartyy(agentPartyOptions);
    } else if (dropdown2Value === "PartyWise") {
      // Map party names to the desired format
      const formattedPartyNames = partyNames.map((party) => ({
        value: party.LName,
        label: party.LName,
      }));
      // Add the 'All' option
      const agentPartyOptions = [
        { value: "all", label: "All" },
        ...formattedPartyNames,
      ];
      setAgentPartyy(agentPartyOptions);
    }
  }, [dropdown2Value, agentNames, partyNames]);

  const columns = [
    {
      Header: "S No",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center", width: "50px" }}>
          {row.index + 1}
        </div>
      ),
    },
    {
      accessor: "BillRemark",
      Header: "Doc No",
      width: 180,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "150px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "TrDate",
      Header: "Doc Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "AName",
      Header: "Agent Name",
      width: 150,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
    },
    {
      accessor: "LName",
      Header: "Party Name",
      width: 250,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
    },
    {
      accessor: "CrAmt",
      Header: "Credit",
      width: 150,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "DrAmt",
      Header: "Debit",
      width: 150,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
   
    {
      accessor: "Balance",
      Header: "Balance",
      width: 150,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "PDays",
      Header: "Days",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "50px", textAlign: "center" }}>{value}</div>
      ),
    },
  ];

  // Object to store grouped data
  const groupedData = {};
  // Group data by party name
  rows.forEach((row) => {
    if (!groupedData[row.LName]) {
      groupedData[row.LName] = [];
    }
    groupedData[row.LName].push(row);
  });

  // Array to store formatted rows
  let formattedRows = [];
  // Loop through grouped data and add total rows for each group
  let totalRowIndex = 0; // Unique identifier for total rows
  Object.keys(groupedData).forEach((partyName) => {
    let totalD = 0;
    let totalC = 0;

    let totalDebit = 0;
    let grandDebit = 0
    let totalCredit = 0;
    let grandCredit = 0
    let totalBalance = 0;
    let grandBalance = 0

    // Add data rows for the current party name
    formattedRows.push(...groupedData[partyName]);
    // Iterate through each row in the group
    groupedData[partyName].forEach((row) => {
      // Accumulate debit and credit values
      totalDebit = row.DrAmt;
      totalCredit = row.CrAmt;

      grandDebit += row.DrAmt;
      grandCredit += row.CrAmt;

      grandBalance = grandCredit - grandDebit

      // Calculate balance for each row
      row.Balance =  totalCredit - totalDebit;
      totalBalance += row.Balance;
    });
    console.log(`Total Debit: ${totalDebit}`);
    console.log(`Total Credit: ${totalCredit}`);
    console.log(`Total Balance: ${totalBalance}`);
    formattedRows.push({
      id: `Party_${totalRowIndex}`, // Unique id for total row
      BillNo: `Party_${totalRowIndex}`, // Empty for total row
      TrDate: "", // Empty for total row
      AName: "", // Empty for total row
      LName: "Total", // Total row
      DrAmt: grandDebit.toFixed(2),
      CrAmt: grandCredit.toFixed(2),
      Balance: Math.abs(grandBalance).toFixed(2),
      PDays: "", // Empty for total row
    });
    totalRowIndex++; // Increment unique identifier
  });

  const handleSearch = async () => {
    setShowLoader(true);
    const date = new Date();
    // Get the year, month, and day components from the startDate
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the components in DD-MM-YYYY format for the startDate
    const formattedStartDate = `${year}${month}${day}`;

    const targetPage = menuValue.find((item) => item.FormName === targetRoute);
    let LogId; // Define LogId variable outside the loop
    let DPCode; // Define LogId variable outside the loop

    FormKey = targetPage ? targetPage.FormKey : null;
    Depo.forEach((depo) => {
      if (depo.DpName == selectedLocation) {
        DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
      }
    });
    const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
    LogId = itemFromLocalStorage[0].LogID
    console.log(LogId)
    const AgentPartyCode = dropdown2Value === "AgentWise" ? 0 : 1;
    const Value = dropdown1Value === "all" ? "" : dropdown1Value;
    try {
      const token = Cookies.get("token"); // Retrieve token from cookies
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      const requestBody = {
        UserName: LogId,
        DepotCode: DPCode,
        OutstandingIndex: outStandingIndex,
        SelectedIndex: AgentPartyCode,
        Selectedvalue: Value,
        StartDate: formattedStartDate,
      };
      const BASE_URL = api2;
      // Send POST request to backend endpoint
      const response = await axios.post(
        `${BASE_URL}/outstandingBill`,
        requestBody,
        config
      );
      const CreditorsBills = response.data.AllOutstandingBills;
      setRows(CreditorsBills);
      toast.success("Creditors Bills retrieved successfully.");
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error:", error.message);
      
    } finally {
      setShowLoader(false);
    }
  };

  const handleDownloadAsPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Define table headers
    const headers = columns
    .filter(
      (column) => column.Header !== "S No"
    ) 
    .map((column) => column.Header);
    console.log(headers)
    columns.forEach(column => {
      column.align = "left";
    });
    // Define table data
    const data = formattedRows.map(row => {
      return headers.map((header) => {
        switch (header) {
          case "Doc No":
            return row.BillRemark;
          case "Doc Date":
            return row.TrDate;
            case "Agent Name":
              return row.AName; 
            case "Party Name":
              return row.LName;
            case "Debit":
              return parseFloat(row.DrAmt).toFixed(2);
          case "Credit":
            return parseFloat(row.CrAmt).toFixed(2);
          case "Balance":
            return parseFloat(row.Balance).toFixed(2);
          case "Days":
              return row.PDays;
          default:
            return row[header];
        }
      });
    });
    console.log(rows)
    console.log(formattedRows)

    const companyName = localStorage.getItem("cName");
    const companyLocation = localStorage.getItem("selectedLocation");
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because January is 0
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${day}-${month}-${year}`;

    doc.setFont("helvetica", "bold"); // Set the font to bold variant of Helvetica
    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed

    doc.setFontSize(16);
    doc.text(`${companyName},${companyLocation}`, center, 20, { align: "center" });


    doc.setFontSize(12);
    doc.text(dropdown1Value, center, 27, { align: "center" });
    doc.text(`Date : ${formattedStartDate}`, rightPosition, 20, { align: "right" });

    // const logoImg = new Image();
    // logoImg.src = logo; // Replace 'path/to/your/logo.png' with the actual path to your logo image
    // doc.addImage(logoImg, 'PNG', 10, 9, 50, 20);

    // Add a line below the date range
    doc.setLineWidth(0.5);
    doc.line(center - 30, 22, center + 30, 22);
    const quarterHeight = doc.internal.pageSize.height / 5;

// Add table to PDF
doc.autoTable({
  startY: quarterHeight,
  head: [headers],
  body: data,
  didParseCell: (data) => {
    const row = data.row.index;
    const column = data.column.dataKey;
    console.log(data.column)
    if (column === 4 || column === 5 ||column === 6  ) {
      data.cell.styles.halign = 'right'; // Align Debit, Credit, and Balance columns to the right
    }else{
      data.cell.styles.halign = 'left';
    }

  if (row % 2 !== 0) { // Check if row index is odd
      data.cell.styles.fillColor = "#4FA9A7";
      data.cell.styles.textColor = "#ffffff";
    }
    if (row % 2 === 0) { // Check if row index is even
      // data.table.columns.styles.halign = "right"; // Apply background color to even rows
    }
  }
});

    // Save PDF
    doc.save('Outstanding Cre. Bills.pdf');
};

  const handleDownloadAsExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Recievable_Bills");
    XLSX.writeFile(wb, "Recievable_Bills.xlsx");
  };
  const handleDropdown2Change = (e) => {
    setDropdown2Value(e.target.value);
    formattedRows = [];
  };
  const handleDropdown1Change = (e) => {
    setDropdown1Value(e.target.value);
    formattedRows = [];
  };

  return (
    <div className="">
      {showLoader && <Loader />}
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        OUTSTANDING PAYABLE BILLS
      </h1>
      <div className="flex flex-col lg:flex-row items-start justify-start p-2 space-y-2 lg:space-y-0 lg:space-x-1">
        {/* Party Name Dropdown */}
        <DropDown
          id="dropdown2"
          value={dropdown2Value}
          onChange={handleDropdown2Change}
          options={parties}
          label="Filter"
        />
        <DropDown
          id="dropdown1"
          value={dropdown1Value}
          onChange={handleDropdown1Change}
          options={agentPartyy}
          label="Agent/Party Name"
        />
        <CommonButton onClick={handleSearch} />
      </div>
      {/* <DataTable rows={formattedRows} columns={columns} /> */}
      <DynamicTable columns={columns} rows={formattedRows} />
      <DownloadData onPdfClick={handleDownloadAsPDF}/>
    </div>
  );
};

export default CreditorsReceivableBills;
