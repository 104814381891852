import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiChevronDown, FiMenu , FiChevronUp , FiX } from 'react-icons/fi'; // Importing arrow down and menu icons from react-icons
import Sidebar from './Sidebar';
import logo from '../../assets/logo.jpg'
import Sidebar1 from './TestingSide';
import axios from 'axios';
import { api, api1, api2 } from '../Common/apiURL';
import Cookies from 'js-cookie';

const DropdownMenu = ({ handleLogout }) => {
  return (
    <div className="absolute right-0 top-12 mt-2 w-48 bg-white rounded-md shadow-xl z-10">
      <div className="py-1 bg-[#4FA9A7] text-black hover:bg-[#4FA9C7]">
        <button
          onClick={handleLogout}
          className="block px-2 py-2 text-sm text-white font-bold  w-full text-left"
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
};

const Header = () => {
  // State variables to store data from local storage
  const [companyName, setCompanyName] = useState('');
  const [branch, setBranch] = useState('');
  const [userName, setUserName] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar
  const [openedSubmenu, setOpenedSubmenu] = useState(null);

  const navigate = useNavigate();

  const dummyData = [
    {
      id: 1,
      title: 'Display',
      subItems: [
        {
          id: 11,
          title: 'Sales-Register',
          // subSubItems: ['Stock 1', 'Stock 2', 'Stock 3']
        }
      ],
    },
    {
      id: 2,
      title: 'Report',
      subItems: [
        {
          id: 21,
          title: 'Inventory',
          subSubItems: ['Stock-Summary']
        },
        {
          id: 22,
          title: 'Sales-Report',
          subSubItems: ['Dispatch-Report']
        },
        {
          id: 23,
          title: 'Outstanding',
          subSubItems: ['Receivable-Bills']
        },
      ],
    },
    {
      id: 3,
      title: 'Accounts',
      subItems: [{ id: 31, title: 'Ledger' }, { id: 32, title: 'Debtor-List' }],
    },
  ];

  // Function to handle submenu toggle
  const handleSubmenuToggle = itemId => {
    if (openedSubmenu === itemId) {
      setOpenedSubmenu(null); // Close the submenu if already opened
    } else {
      setOpenedSubmenu(itemId); // Open the submenu
    }
  };

  // Function to fetch data from local storage
  const fetchDataFromLocalStorage = () => {
    const data = JSON.parse(localStorage.getItem('userInfo'));
    const branchName = localStorage.getItem('selectedLocation');
    setBranch(branchName || '');
    if (data && Array.isArray(data) && data.length > 0) {
      const userData = data[0];
      setCompanyName(userData.CName || '');
      setUserName(userData.UserName || '');
    }
  };

  useEffect(() => {
    // Fetch data from local storage when component mounts
    fetchDataFromLocalStorage();
  }, []);

  const handleLogout = async(e) => {
    const isLoggedIn = false; // Set to false for logout
    const LogValue = localStorage.getItem("LogValue")
    console.log(LogValue)
    const requestBody = {
      LogValue: LogValue,
    };
    const BASE_URL = api2
    const response = await axios.post(`${BASE_URL}/auth/logout`,requestBody);
    e.preventDefault();
    Cookies.remove('token');
    localStorage.clear(); // Clear all items from localStorage
    Cookies.set('isLoggedIn', isLoggedIn);
    navigate('/'); // Navigate to the login page
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
    // console.log(isSidebarOpen)
  };

  return (
    <div className=" p-2 sticky top-0 z-1000000 ">
      <div className="container mx-auto flex items-center justify-between w-full" >
        {/* Hamburger menu icon */}
        <div className="block lg:hidden">
          <button onClick={handleSidebarToggle} className="text-black focus:outline-none">
            <FiMenu className="h-6 w-6 mr-2 mt-2" color='white' />
          </button>
        </div>
        {/* Left side logos */}
        <div className="flex items-center flex-shrink-0 text-white mr-5">
          <span className="d-block font-semibold text-md tracking-tight">
            {companyName} <br/>
            {branch}
          </span>
        </div>
        <div className="flex-grow" ></div>
        {/* Right side logos */}
        <div className="flex items-center flex-shrink-0 text-white mr-2 cursor-pointer p-1" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <span className="font-bold text-md tracking-tight">{userName}</span>
          <FiChevronDown className="ml-1 mt-2" /> {/* Arrow down icon */}
        </div>
        {isDropdownOpen && <DropdownMenu handleLogout={handleLogout} />}
      </div>

      {/* Sidebar */}
      {isSidebarOpen && (
          <Sidebar1/>
        )}    
    </div>
  );
};

export default Header;
