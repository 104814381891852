import React from 'react';
import { FiSearch } from 'react-icons/fi';

const AddButton = ({ onClick }) => {
  return (
    <div className='w-full'>
      <button onClick={onClick} className="bg-[#4FA9A7] hover:bg-[#4FA9B7] text-white w-full font-bold py-4 px-4 rounded">
        Add
      </button>
    </div>
  );
}

export default AddButton;
