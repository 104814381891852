import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

function InvoiceDetails() {
  // State for invoice number and customer details
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [id, setId] = useState(1);
  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    email: '',
    address: '',
    city:'',
    pinCode:'',
    mobileNumber:'',
    GSTIN:''
  });

  // State for item details
  const [items, setItems] = useState([]);
  const [rows, setRows] = useState([]);

  // Handler functions for input changes
  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleCustomerDetailsChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails({ ...customerDetails, [name]: value });
  };

  const handleAddItem = () => {
  // Generate a unique id for the new item using uuid
  const newItemId = uuidv4();
  
  // Add an empty item to the items array with a unique id
  setItems([...items, { id: id, name: '', price: 0, quantity: 1, taxType: '', taxAmount: 0, totalAmount: 0 }]);
};
  

  const handleItemChange = (index, field, value) => {
    // Update the item details based on index and field
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  const handleSubmit = () => {
    // Generate new invoice number
    const newInvoiceNumber = `INV-${invoiceNumber.toString().padStart(3, '0')}`;
    
    // Update rows with current items and invoice number
    const updatedRows = [...rows, ...items.map(item => ({ ...item, invoiceNumber: newInvoiceNumber }))];

    // Update invoice number for next submission
    setInvoiceNumber(invoiceNumber + 1);
    setId(id + 1);

    // Output customer details and items to console
    console.log("Customer Details:", customerDetails);
    console.log("Updated Rows:", updatedRows);

    // Update rows state and clear items state
    setRows(updatedRows);
    setItems([]);
  };
  const columns = [
    { field: 'id', headerName: 'S No', flex: 1 },
    // { field: 'invoiceNumber', headerName: 'Invoice Number', flex: 1 },
    { field: 'name', headerName: 'Item Name', flex: 1 },
    { field: 'price', headerName: 'Price', flex: 1 },
    { field: 'quantity', headerName: 'Quantity', flex: 1 },
    { field: 'taxType', headerName: 'Tax Type', flex: 1 },
    { field: 'taxAmount', headerName: 'Tax Amount', flex: 1 },
    { field: 'totalAmount', headerName: 'Total Amount', flex: 1 }
  ];

  return (
    <div className="mb-8 p-3">
    <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        INVOICE
    </h1>
      <div className="mb-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={customerDetails.name}
              onChange={handleCustomerDetailsChange}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
          <div>
            <label htmlFor="email" className="block">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={customerDetails.email}
              onChange={handleCustomerDetailsChange}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
          <div className="grid grid-cols-3 gap-4 w-full">
          <div>
            <label htmlFor="address" className="block">Address:</label>
            <input
              type="text"
              id="address"
              name="address"
              value={customerDetails.address}
              onChange={handleCustomerDetailsChange}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
          <div>
            <label htmlFor="city" className="block">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={customerDetails.city}
              onChange={handleCustomerDetailsChange}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
          <div>
            <label htmlFor="pinCode" className="block">PinCode:</label>
            <input
              type="number"
              id="pinCode"
              name="pinCode"
              value={customerDetails.pinCode}
              onChange={handleCustomerDetailsChange}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
                <div>
                    <label htmlFor="mobileNumber" className="block">Mobile Number:</label>
                    <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={customerDetails.mobileNumber}
                    onChange={handleCustomerDetailsChange}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full"
                    />
                </div>
                <div>
                    <label htmlFor="GSTIN" className="block">GSTIN:</label>
                    <input
                    type="text"
                    id="GSTIN"
                    name="GSTIN"
                    value={customerDetails.GSTIN}
                    onChange={handleCustomerDetailsChange}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full"
                    />
                </div>
        </div>

          <div className='col-span-2 bg-gray-600 p-2 rounded-lg text-center cursor-pointer'>
            <button onClick={handleAddItem} className='text-white font-semibold'>Add Items</button>
          </div>
          
        </div>
      </div>

      <div>
            {items.map((item, index) => (
            <div key={index} className="mb-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label htmlFor="itemName" className="block">Item Name</label>
                    <input
                        type="text"
                        id="address"
                        placeholder="Item Name"
                        value={item.name}
                        onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                        className="border border-gray-300 rounded-md px-2 py-1 w-full"
                    />
                </div>
                <div>
                    <label htmlFor="price" className="block">Price</label>
                    <input
                        type="number"
                        placeholder="Price"
                        value={item.price}
                        onChange={(e) => handleItemChange(index, 'price', parseFloat(e.target.value))}
                        className="border border-gray-300 rounded-md px-2 py-1 w-full"
                    />
                </div>
            </div>
                <div className="col-span-2">
                    <label htmlFor="Quantity" className="block">Quantity</label>
                    <input
                    type="number"
                    placeholder="Quantity"
                    value={item.quantity}
                    onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value))}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full mb-2"
                    />
                </div>
            <div className="grid grid-cols-2 gap-4">
                <div >
                <label htmlFor="taxType" className="block">Tax Type</label>
                <input
                    type="text"
                    placeholder="Tax Type"
                    value={item.taxType}
                    onChange={(e) => handleItemChange(index, 'taxType', e.target.value)}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full mb-2"
                    />
                </div>
                <div >
                <label htmlFor="taxAmount" className="block">Tax Amount</label>
                <input
                    type="number"
                    placeholder="Tax Amount"
                    value={item.taxAmount}
                    onChange={(e) => handleItemChange(index, 'taxAmount', parseFloat(e.target.value))}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full mb-2"
                    />
                </div>
            </div>
                <div className="col-span-2">
                <label htmlFor="totalAmount" className="block">Total Amount</label>
                <input
                    type="number"
                    placeholder="Total Amount"
                    value={item.totalAmount}
                    onChange={(e) => handleItemChange(index, 'totalAmount', parseFloat(e.target.value))}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full mb-2"
                    />
                </div>
                <div className='col-span-2 bg-gray-600 p-2 rounded-lg text-center cursor-pointer'>
                    <button onClick={handleSubmit} className='text-white font-semibold'>Submit</button>
                </div>
            </div>
            ))}

            <div style={{ height: 400, width: '100%' }}>
            <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            checkboxSelection={false}
            disableSelectionOnClick
            //   onEditCellChangeCommitted={(params) => handleItemChange(params.id, params.field, params.props.value)}
            />
            </div>
      </div>
    </div>
  );
}

export default InvoiceDetails;
