import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { TbReportSearch } from "react-icons/tb";
import {   FaChartBar,  FaRupeeSign  } from 'react-icons/fa';
import { FaFileCircleQuestion } from "react-icons/fa6";

import { MdAccountBalanceWallet } from "react-icons/md";

const Sidebar = () => {
  const [openedSubmenu, setOpenedSubmenu] = useState(null); // State for opened submenu
  const [openedSubSubmenu, setOpenedSubSubmenu] = useState(null); // State for opened submenu
  const menu = JSON.parse(localStorage.getItem("Menu"))
  const UserData = JSON.parse(localStorage.getItem("userInfo"))
  const logo = UserData[0].Logo

  let sideBarMenu = menu
  // console.log(sideBarMenu)

  // Group the menu items by their FGroup values
  const groupedMenu = sideBarMenu.reduce((acc, curr) => {
    if (!acc[curr.FGroup]) {
      acc[curr.FGroup] = [];
    }
    acc[curr.FGroup].push(curr);
    return acc;
  }, {});

  const handleSubmenuToggle = (fGroup) => {
    setOpenedSubmenu(prevState => (prevState === fGroup ? null : fGroup)); // Toggle submenu state
    // setOpenedSubSubmenu(null); // Close subsubmenu when submenu is toggled
  };

  const handleSubSubmenuToggle = (subMenuItem) => {
    setOpenedSubSubmenu(prevState => (prevState === subMenuItem.FormKey ? null : subMenuItem.FormKey)); // Toggle subsubmenu state
  };

  const enquiryFormData = {
    formKey: 'enquiry-form',
    formName: 'Enquiry Form',
    // Add more properties as needed
  };
  let enquiry = true

  return (
      <aside className="flex flex-col h-full w-full overflow-y-auto">
        {/* Company Logo */}
        <div className="p-2 flex justify-center items-center">
        <div className='left-[50%] w-[50%]'>
          <Link to='/dashboard'>
              <img src={logo} alt="Logo" className="p-2" />
              </Link>
        </div>
        </div>
        {/* Menu */}
        <div className="p-2 mt-5 cursor-pointer">
          {Object.entries(groupedMenu).map(([fGroup, menuItems]) => (
            <ul key={fGroup}>
              <li className="text-black mb-2 font-bold text-1xl relative bg-[#4FA9B7] p-2 rounded-md" onClick={() => handleSubmenuToggle(fGroup)}>
                <button className="text-white ml-2 mt-5 absolute top-0 right-0 transform -translate-y-1/2">
                  {openedSubmenu === null || openedSubmenu !== fGroup ? <FiChevronDown /> : <FiChevronUp />}
                </button>
                <span>
                  {fGroup === '2' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <FaRupeeSign />
                    Transaction
                    </div>
                    </>
                  )}
                  {fGroup === '3' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <FaChartBar /> Display
                    </div>
                    </>
                  )}
                  {fGroup === '4' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <TbReportSearch /> Reports
                    </div>
                    </>
                  )}
                  {fGroup === '12' && (
                    <>
                    <div className='flex gap-2 items-center'>
                    <MdAccountBalanceWallet /> Accounts
                    </div>
                    </>
                  )}
                </span>
                {openedSubmenu === fGroup && (
                  <ul className="mt-1 ml-3 p-2">
                    {menuItems.map(menuItem => (
                      <li key={menuItem.FormKey} className="text-white ">
                        {menuItem.SubCategory === 1 ? (
                          <div>
                             <span>{menuItem.FormName}</span>
                            {openedSubSubmenu === menuItem.FormKey && (
                              <ul>
                                {sideBarMenu
                                  .filter(item => item.SubCategory === 0 && item.FormKey.toString().startsWith(menuItem.FormKey.toString()))
                                  .map(subMenuItem => (
                                    <li key={subMenuItem.FormKey}>
                                    <Link to={`/dashboard/${subMenuItem.FormName.replace(/\s+/g, '-').toLowerCase()}`}>{subMenuItem.FormName}</Link>
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          <ul className='ml-4 text-black bg-[#4FA9B7] rounded-md mb-1 p-1'>
                              <Link to={`/${menuItem.FormName.replace(/\s+/g, '-').toLowerCase()}`}>
                                  <li key={menuItem.FormKey} className='list-disc'>
                                      {menuItem.FormName}
                                  </li>
                              </Link>
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          ))}
        </div>
      </aside>
  );
};

export default Sidebar;
