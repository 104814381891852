import React, { useEffect, useState, useMemo } from "react";
import CommonButton from "../Common/FindButton";
import DropDown from "../Common/Dropdown";
import CustomDatePicker from "../Common/DatePicker";
import DataTable from "../Common/DataTable";
import Loader from "../Common/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx"; // Import xlsx library
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api, api2 } from "../Common/apiURL";
import DynamicTable from "../Common/NewDataTable";
import { useNavigate } from "react-router-dom";

const DispatchReport = () => {
  const [dropdown2Value, setDropdown2Value] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [partyNames, setPartyNames] = useState([]);

  const menuValue = JSON.parse(localStorage.getItem("Menu")) || [];
  const Depo = JSON.parse(localStorage.getItem("Depo")) || [];
  const selectedLocation = localStorage.getItem("selectedLocation");
  const targetRoute = "Dispatch Report";
  let FormKey;

  useEffect(() => {
    fetchUserData();
  }, []);
  const navigate = useNavigate();

  const fetchUserData = async () => {
    try {
      const targetPage = menuValue.find(
        (item) => item.FormName === targetRoute
      );
      FormKey = targetPage ? targetPage.FormKey : null;

      let LogId = null;
      let DPCode = null;

      Depo.forEach((depo) => {
        if (depo.DpName === selectedLocation) {
          DPCode = depo.DPCode;
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)

      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const BASE_URL = api2;
      const requestBody1 = {
        UserName: LogId,
        FormKey: FormKey,
        DPCode: DPCode,
        AgentName: dropdown2Value,
      };
      const response1 = await axios.post(
        `${BASE_URL}/parties`,
        requestBody1,
        config
      );
      const partyNamesData = response1.data.resultWebPartyList.map(
        (row) => row.LName
      );
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      setPartyNames(uniquePartyNames);
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error fetching user data:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No",
        align: "center",
        headerAlign: "center",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center", width: "50px" }}>
            {row.index + 1}
          </div>
        ),
      },
      {
        accessor: "BillNo",
        Header: "Bill No",
        width: 130,
        align: "center",
        headerAlign: "center",
        Cell: ({ value }) => <div style={{ width: "120px" }}>{value}</div>,
      },
      {
        accessor: "TDate",
        Header: "Date",
        width: 100,
        align: "center",
        headerAlign: "center",
        Cell: ({ value }) => <div style={{ width: "90px" }}>{value}</div>,
      },
      {
        accessor: "PartyName",
        Header: "Party Name",
        width: 280,
        align: "left",
        headerAlign: "center",
        Cell: ({ value }) => <div style={{ width: "280px" }}>{value}</div>,
      },
      {
        accessor: "Iname",
        Header: "Item Name",
        width: 200,
        align: "left",
        headerAlign: "center",
        Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
      },
      {
        accessor: "BName",
        Header: "Brand",
        width: 70,
        align: "center",
        headerAlign: "center",
      },
      {
        accessor: "TotQty",
        Header: "Qty",
        width: 80,
        align: "center",
        headerAlign: "center",
      },
      {
        accessor: "Uname",
        Header: "Unit",
        width: 50,
        align: "center",
        headerAlign: "center",
      },
      {
        accessor: "Rate",
        Header: "Rate",
        width: 80,
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            {parseFloat(value).toFixed(2)}
          </div>
        ), // Align cell content to the right
      },
      {
        accessor: "TotAmt",
        Header: "Amount",
        width: 100,
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            {parseFloat(value).toFixed(2)}
          </div>
        ),
      },
    ],
    []
  );

  const totalPrice = useMemo(
    () => rows.reduce((total, row) => total + parseFloat(row.amount), 0),
    [rows]
  );

  const formattedRows = useMemo(
    () => [
      ...rows.map((row) => ({
        ...row,
        amount: parseFloat(row.amount).toFixed(2),
      })),
      // { id: '', billNo: '', date: '', PartyName: 'Grand Total ', itemName: '', brand: '', qty: '', unit: '', rate: '', amount: totalPrice.toFixed(2) }
    ],
    [rows, totalPrice]
  );

  const parties = useMemo(
    () => [
      { value: "all", label: "All" },
      ...partyNames.map((partyName, index) => ({
        value: partyName,
        label: partyName,
      })),
    ],
    [partyNames]
  );

  const handleSearch = async () => {
    setShowLoader(true);
    try {
      //   const apiUrl = '';
      const BASE_URL = api2;
      const apiUrl = `${BASE_URL}/dispatchReport`;

      // Get the year, month, and day components from the startDate
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, "0");
      const day = String(startDate.getDate()).padStart(2, "0");
      const formattedStartDate = `${year}${month}${day}`;

      // Get the year, month, and day components from the endDate
      const endDateYear = endDate.getFullYear();
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
      const endDateDay = String(endDate.getDate()).padStart(2, "0");
      const formattedEndDate = `${endDateYear}${endDateMonth}${endDateDay}`;

      const targetPage = menuValue.find(
        (item) => item.FormName === targetRoute
      );
      let LogId;
      let DPCode;

      FormKey = targetPage ? targetPage.FormKey : null;
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID
      console.log(LogId)

      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      const requestData = {
        UserName: LogId,
        DepotCode: DPCode,
        Selectedvalue: dropdown2Value === "all" ? "" : dropdown2Value,
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
      };

      const response = await axios.post(apiUrl, requestData, config);
      const AllDispatchReport = response.data.AllDispatchReport;

      const totalPrice =
        AllDispatchReport.reduce(
          (total, row) => total + parseFloat(row.TotAmt),
          0
        ) || "00.00";

      const formattedRows = [
        ...AllDispatchReport.map((row) => ({
          ...row,
          TotAmt: parseFloat(row.TotAmt).toFixed(2),
        })),
        // {
        //   billNo: "",
        //   date: "",
        //   PartyName: "Grand Total ",
        //   itemName: "",
        //   brand: "",
        //   qty: "",
        //   unit: "",
        //   rate: "",
        //   TotAmt: totalPrice.toFixed(2),
        // },
      ];
      setRows(formattedRows);
      toast.success("Dispatch Report retrieved successfully.");
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error:", error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDownloadAsPDF = () => {
    // Generate PDF
  };

  const handleDownloadAsExcel = () => {
    // Generate Excel
  };

  const handleDropdownChange = (e) => {
    setDropdown2Value(e.target.value);
    setRows([]);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setRows([]);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setRows([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <div>
      {showLoader && <Loader />}
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        DISPATCH REPORT
      </h1>
      <div onKeyDown={handleKeyDown}>
        <div className="flex flex-col lg:flex-row items-start justify-start p-2 space-y-2 lg:space-y-0 lg:space-x-1">
          <DropDown
            id="dropdown2"
            value={dropdown2Value}
            onChange={handleDropdownChange}
            options={parties}
            label="Party Name"
          />
          <div className="flex flex-row space-x-1 w-full">
            <CustomDatePicker
              id="startDate"
              selected={startDate}
              onChange={handleStartDateChange}
              className="p-1 border rounded-md outline-none w-full"
              label="Start Date"
            />
            <CustomDatePicker
              id="endDate"
              selected={endDate}
              onChange={handleEndDateChange}
              className="p-1 border rounded-md outline-none w-full"
              label="End Date"
            />
          </div>
          <CommonButton onClick={handleSearch} />
        </div>
      </div>
      {/* <DataTable rows={formattedRows} columns={columns} /> */}
      <DynamicTable columns={columns} rows={rows} />
    </div>
  );
};

export default DispatchReport;
