import React from 'react'
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <>
    <div className="fixed top-0 left-0 w-full h-full bg-gray-600 bg-opacity-80 flex justify-center items-center p-5 z-50">
        <RingLoader color={'#ffffff'} css={override} size={150} />
    </div>
    </>
  )
}

export default Loader
