import React, { useEffect, useState } from "react";
import DropDown from "../Common/Dropdown";
import axios from "axios";
import Cookies from "js-cookie";
import { api2 } from "../Common/apiURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDatePicker from "../Common/DatePicker";
import { useNavigate } from "react-router-dom";

const BillModal = ({
  isOpen,
  onClose,
  rowData,
  updateRowData
}) => {
    const [formData, setFormData] = useState(rowData);
    const [dropdown1Value, setDropdown1Value] = useState("");
    const [partyNames, setPartyNames] = useState([]);
    const [days, setDays] = useState("1");
    const [date, setDate] = useState(new Date());
    const [allowedBy, setAllowedBy] = useState("");
    const [billingAmount, setBillingAmount] = useState("");
    const [remark1, setRemark1] = useState("");
    const [remark2, setRemark2] = useState("");
    const [endDate, setEndDate] = useState("");
    const [rows, setRows] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClear = () => {
    setDropdown1Value("");
    setDate(
      new Date().toLocaleDateString("en-GB").split("/").reverse().join("/")
    );
    setDays("0");
    setAllowedBy("");
    setBillingAmount("");
    setRemark1("");
    setRemark2("");
    setEndDate("");
    toast.success("Clear Form Successfully");
  };

  const navigate = useNavigate();


  useEffect(() => {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + parseInt(days - 1));
    const formattedEndDate = `${endDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${endDate.getFullYear()}`;
    setEndDate(formattedEndDate);
  }, [date, days]);

  const parsedDate = new Date(date);
  const formattedDate = `${parsedDate.getDate().toString().padStart(2, "0")}/${(
    parsedDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${parsedDate.getFullYear()}`;


  const handleSave = async () => {
    try {
      // Send API request to update the data with formData
      await axios.put(`your_update_api_url/${formData.id}`, formData);
      // Call handleSubmit from parent component to update the state
      handleSubmit(formData);
      onClose();
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error updating data:", error);
    }
  };

  let Depo = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const BASE_URL = api2;
  var LogId = null;
  var DPCode = null;


  const fetchData = async () => {
    try {
      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          LogId = depo.LogID;
          DPCode = depo.DPCode;
        }
      });
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const requestBody = {
        Username: LogId,
        FormKey: 0,
        DPCode: DPCode,
        AgentName: "",
      };
      const BASE_URL = api2;
      const response = await axios.post(
        `${BASE_URL}/parties`,
        requestBody,
        config
      );
      const partyNamesData = response.data.resultWebPartyList.map(
        (row) => row.LName
      );
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      setPartyNames(uniquePartyNames);
    } catch (error) {
      // if(error.response.status){
      //   alert('Please Login Again!!!')
      //   navigate('/')
      // }
      console.error("Error fetching data:", error);
    }
  };
  const handleDropdown1Change = (e) => {
    setDropdown1Value(e.target.value);
  };
  const parties = [
    { value: "", label: "Select Party Name" },
    ...partyNames.map((partyName, index) => ({
      value: partyName,
      label: partyName,
    })),
  ];
  useEffect(()=>{
    fetchData()
  },[])

  const handleSubmit = async () => {
    setShowLoader(true);
    Depo.forEach((depo) => {
      if (depo.DpName == selectedLocation) {
        LogId = depo.LogID;
        DPCode = depo.DPCode;
      }
    });
    const formattedDate = `${parsedDate.getFullYear()}${(
      parsedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${parsedDate.getDate().toString().padStart(2, "0")}`;
    const RefCnt = 2;
    const DocID = "";

// Check if each field is empty and set it to the rowData value if empty
const partyNameValue = dropdown1Value === "" ? rowData.LName : dropdown1Value;
const dateValue = date === "" ? rowData.date : formattedDate;
const daysValue = days === "" ? rowData.ForDays : days;
const allowedByValue = allowedBy === "" ? rowData.AllowedBy : allowedBy;
const billingAmountValue = billingAmount === "" ? rowData.Amount : billingAmount;
const remark1Value = remark1 === "" ? rowData.Remark1 : remark1;
const remark2Value = remark2 === "" ? rowData.Remark2 : remark2;

const formData = {
  LogId,
  id: rows.length + 1,
  partyName: partyNameValue,
  date: dateValue,
  days: daysValue,
  allowedBy: allowedByValue,
  amount: billingAmountValue,
  remark1: remark1Value,
  remark2: remark2Value,
  endDate,
  RefCnt,
  DocID,
  DPCode,
};

const form = {
  UserName: LogId,
  DpCode: DPCode,
  PartyName: partyNameValue,
  VDate: dateValue,
  ForDays: daysValue,
  AllowedBy: allowedByValue,
  Amount: billingAmountValue,
  Remark1: remark1Value,
  Remark2: remark2Value,
  RefCnt,
  DocID: rowData.DocID,
};
    console.log("Payload Data:", form);
    // const BASE_URL2 = api2
    const token = Cookies.get("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    };
    const response = await axios.post(
      `${BASE_URL}/billPermission`,
      form,
      config
    );
    // console.log(response.data.data)
    const updatedRowData = response.data; // Use response.data directly
    // console.log(updatedRowData)
    updateRowData(updatedRowData);
    toast.success("Updated Bill Permission successfully!");
    onClose()
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  };

  if (!isOpen) return null;

  return (
    <div>
      <div className={`overlay ${isOpen ? 'block' : 'hidden'} opacity-50%`} />
      <div className={`modal ${isOpen ? 'block' : 'hidden'} fixed top-[25%] z-999 w-[97%] h-[70%] lg:w-[75%] lg:ml-[2%]`}>
        <div className="modal-content bg-[#4FA9A7] rounded-lg " >
          <span className="close flex justify-end p-1 text-4xl cursor-pointer" onClick={onClose}>
            &times;
          </span>
          <div className="mb-4 p-1">
            <div className="grid grid-cols-1 gap-4 p-1 rounded-lg">
            
              <div className="-mt-[8px]">
                <DropDown
                  id="dropdown1"
                  value={ dropdown1Value || rowData.LName}
                  onChange={(e) => setDropdown1Value(e.target.value)}
                  options={parties}
                  label="Party Name"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 p-1">
              <div>
                <label htmlFor="allowedBy" className="block">
                  Allowed By:
                </label>
                <input
                  type="text"
                  id="allowedBy"
                  value={allowedBy || rowData.AllowedBy}
                  onChange={(e) => setAllowedBy(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1 w-full"
                />
              </div>
              <div>
                <label htmlFor="billingAmount" className="block">
                  Amount:
                </label>
                <input
                  type="text"
                  id="billingAmount"
                  value={billingAmount || rowData.Amount}
                  onChange={(e) => setBillingAmount(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1 w-full"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 p-1">
              <div>
            <CustomDatePicker
              id="date"
              selected={date}
              // value={formattedDate}
              onChange={(newDate) => setDate(newDate)}
              className="border border-gray-300 rounded-md px-2 py-1 w-full"
              label="Date"
            />
          </div>
              <div>
                <label htmlFor="days" className="block">
                  For Days:
                </label>
                <input
                  type="number"
                  id="days"
                  value={days || rowData.ForDays}
                  onChange={(e) => setDays(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1 w-full"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 p-1">
              <div>
                <label htmlFor="remark1" className="block">
                  Remark 1:
                </label>
                <input
                  type="text"
                  id="remark1"
                  value={remark1 || rowData.Remark1}
                  onChange={(e) => setRemark1(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1 w-full"
                />
              </div>
              <div>
                <label htmlFor="remark2" className="block">
                  Remark 2:
                </label>
                <input
                  type="text"
                  id="remark2"
                  value={remark2 || rowData.Remark2}
                  onChange={(e) => setRemark2(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1 w-full"
                />
              </div>
              
            </div>
            <div className="w-full mt-5 p-2">
                <button
                  onClick={handleSubmit}
                  className="bg-[#1FA1B7] hover:bg-[#4FA9B7] text-white w-full font-bold py-4 px-4 rounded"
                >
                  Update
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillModal;
