import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./components/LoginPage/Login";
import Dashboard from "./components/DashBoard/Main";
import Content from "./components/DashBoard/Content";
import Footer from "./components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import Trial from "./components/LoginPage/Trial";

function App() {
  // Check if the user is logged in from localStorage on initial render
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  useEffect(() => {
    function getFinancialYear(date = new Date()) {
      const fiscalYearStart = new Date(date.getFullYear(), 3, 1); 
      const fiscalYearEnd = new Date(date.getFullYear() + 1, 2, 31);
      
      date.setMonth(date.getMonth() - 1);
      
      if (date >= fiscalYearStart && date <= fiscalYearEnd) {
          return `${fiscalYearStart.getFullYear()}-${fiscalYearEnd.getFullYear()}`;
      } else if (date < fiscalYearStart) {
          return `${fiscalYearStart.getFullYear() - 1}-${fiscalYearEnd.getFullYear() - 1}`;
      } else {
          return `${fiscalYearStart.getFullYear() + 1}-${fiscalYearEnd.getFullYear() + 1}`;
      }
    }
  
    // Example usage:
    const currentDate = new Date();
    const financialYear = getFinancialYear(currentDate);
    localStorage.setItem('FinancialYear' , financialYear);
  }, []);

  // Function to handle login
  const handleLogin = () => {
    // Perform login logic here...
    setIsLoggedIn(true); // Update login status
    localStorage.setItem("isLoggedIn", "true"); // Persist login status in localStorage
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login handleLogin={handleLogin} />} />
          <Route path="/trial" element={<Trial />} />
          {isLoggedIn && ( 
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/:subPage" element={<Content />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
