import React, { useEffect, useState } from "react";
import CommonButton from "../Common/FindButton";
import DropDown from "../Common/Dropdown";
import CustomDatePicker from "../Common/DatePicker";
import Loader from "../Common/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx"; // Import xlsx library
import axios from "axios";
import Cookies from "js-cookie";
import { api1, api2 } from "../Common/apiURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicTable from "../Common/NewDataTable";
import DownloadData from "../Common/DownloadData";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/Logo.png'

const Ledger = () => {
  const [dropdown2Value, setDropdown2Value] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [partyNames, setPartyNames] = useState([]);
  const [rows, setRows] = useState([]);
  const [openingBal, setOpeningBal] = useState({});
  const [LCode, setLCode] = useState("");
  const [resultWebPartyList, setResultWebPartyList] = useState([]); // State to store the result
  const [balAmtResult, setBalAmtResult] = useState([]); // State to store the result
  const [ledgerDataResult, setLedgerDataResult] = useState([]); // State to store the result
  const [totalBal, setTotalBal] = useState([]); // State to store the result

  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depo = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  let targetRoute = "Ledger";
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const targetPage = menuValue.find(
        (item) => item.FormName === targetRoute
      );
      var FormKey = targetPage ? targetPage.FormKey : null;

      var LogId = null; // Initialize LogId
      var DPCode = null; // Initialize DPCode

      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID

      // Create the request body object with updated values
      const requestBody = {
        UserName: LogId,
        FormKey: FormKey,
        DPCode: DPCode,
        AgentName: "",
      };

      const token = Cookies.get("token"); // Retrieve token from cookies
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const BASE_URL = api2;
      const response = await axios.post(
        `${BASE_URL}/parties`,
        requestBody,
        config
      );
      // console.log(response.data)

      const resultWebPartyList = response.data.resultWebPartyList;
      // console.log(resultWebPartyList)
      setResultWebPartyList(resultWebPartyList); // Store the result
      // Party Names
      const partyNamesData = resultWebPartyList.map((row) => row.LName);
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      setPartyNames(uniquePartyNames);
      // setDropdown2Value(uniquePartyNames);
      // setRows(userData);
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error fetching user data:", error);
    }
  };
  const totalDebit = ledgerDataResult.reduce(
    (total, row) => total + parseFloat(row.DrAmt),
    0
  );
  // console.log(totalDebit)
  const totalCredit = ledgerDataResult.reduce(
    (total, row) => total + parseFloat(row.CrAmt),
    0
  );
  const totalBalance = ledgerDataResult.reduce(
    (total, row) => total + (totalDebit - totalCredit),
    0
  );
  // console.log(ledgerDataResult)

  // Combine Remark 1 and Remark 2
  const combineRemarks = (remark1, remark2) => {
    return remark1 && remark2 ? `${remark1}, ${remark2}` : remark1 || remark2;
  };

  let openingBalance = 0;

  // if (balAmtResult && balAmtResult.length > 0 && balAmtResult[0].hasOwnProperty('BalAmt')) {
  //   // Assign the value of BalAmt to openingBalance
  //   openingBalance = parseFloat(balAmtResult[0].BalAmt);
  // }

  const columns = [
    {
      Header: "S No",
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center", width: "50px" }}>
          {row.index + 1}
        </div>
      ),
    },
    {
      accessor: "VouNo",
      Header: "No",
      width: 70,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "50px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "VDate",
      Header: "Date",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          // Return empty string or any other default value if params.value is not a valid date
          return "";
        }
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      accessor: "LName",
      Header: "Particulars",
      width: 200,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "240px", textAlign: "left" }}>{value}</div>
      ),
    },
    {
      accessor: "DpAbbr",
      Header: "Depot",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "center" }}>
        {value}
        </div>
      ),
    },
    {
      accessor: "DrAmt",
      Header: "Debit",
      width: 100,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "CrAmt",
      Header: "Credit",
      width: 100,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "Balance",
      Header: "Balance",
      width: 120,
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "120px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    },
    {
      accessor: "extra",
      Header: "",
      width: 100,
      align: "left",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "Vtype",
      Header: "VType",
      width: 100,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "100px", textAlign: "center" }}>{value}</div>
      ),
    },
    {
      accessor: "Remark1",
      Header: "Remark",
      width: 200,
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ width: "200px", textAlign: "center" }}>{value}</div>
      ),
    },
  ];

  const handleSearch = async () => {
    setShowLoader(true);
    if (!dropdown2Value) {
      toast.error("Please Select Party Name");
      setShowLoader(false);
      return;
    }

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;

    const endDateYear = endDate.getFullYear();
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
    const endDateDay = String(endDate.getDate()).padStart(2, "0");
    const formattedEndDate = `${endDateYear}${endDateMonth}${endDateDay}`;

    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      let LogId;
      let DPCode;

      Depo.forEach((depo) => {
        if (depo.DpName == selectedLocation) {
          DPCode = depo.DPCode; // Update DPCode if selectedLocation matches
        }
      });
      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID

      const requestData = {
        UserName: LogId,
        DepotCode: DPCode,
        PartyCode: LCode,
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
      };

      const BASE_URL = api2;
      const response = await axios.post(
        `${BASE_URL}/ledger`,
        requestData,
        config
      );
      const balAmtResult = response.data.balAmtResult.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      const ledgerDataResult = response.data.ledgerDataResult.map(
        (row, index) => ({
          ...row,
          id: index + 1,
        })
      );
      setBalAmtResult(balAmtResult);
      setLedgerDataResult(ledgerDataResult);
      toast.success("Ledger Data retrieved successfully.");
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
      console.error("Error fetching filtered data:", error);
      toast.error("Failed to fetch data. Please try again later.");
    } finally {
      setShowLoader(false);
    }
  };

  const parties = [
    { value: "", label: "Select Party Name", disabled: true },
    ...partyNames.map((partyName, index) => ({
      value: partyName,
      label: partyName,
    })),
  ];

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setDropdown2Value(selectedValue);
    const selectedItem = resultWebPartyList.find(
      (item) => item.LName === selectedValue
    );
    if (selectedItem) {
      // console.log("Selected LCode:", selectedItem.LCode);
      setLCode(selectedItem.LCode);
    } else {
      // console.log("Item not found in the list");
    }
    setLedgerDataResult([]);
  };

  const grandTotalRow = {
    id: "",
    VouNo: "",
    LName: "Grand Total",
    DrAmt: totalDebit.toFixed(2),
    CrAmt: totalCredit.toFixed(2),
    Balance: opBal,
    combineRemarks: "",
  };

  const openingBalRow = {
    id: "",
    VouNo: "",
    LName: "Opening Balance",
    DrAmt: "0.00",
    CrAmt: "0.00",
    Balance: balAmtResult?.[0]?.BalAmt
      ? Math.abs(balAmtResult[0].BalAmt).toFixed(2)
      : "0.00",
    combineRemarks: "",
    extra: "",
  };
  // Perform null check before accessing balAmtResult and its elements
  if (balAmtResult && balAmtResult[0] && balAmtResult[0].BalAmt !== undefined) {
    var opBal = balAmtResult[0].BalAmt.toFixed(2);
    openingBalRow.Balance = Math.abs(balAmtResult[0].BalAmt).toFixed(2);
    openingBalRow.extra = balAmtResult[0].BalAmt >= 0 ? "Dr" : "Cr";
    // console.log(opBal)
  }
  let bal = parseFloat(opBal);
  // console.log(ledgerDataResult)
const formattedRows = [
    openingBalRow,
    ...ledgerDataResult.map((row, index) => {
        const debit = parseFloat(row.DrAmt || 0);
        const credit = parseFloat(row.CrAmt || 0);
        // console.log(openingBalRow.extra)
        bal = debit + bal; // Update balance for the current row
        bal = bal - credit; // Update balance for the current row
        // console.log(bal)
        const extra = bal > 0 ? "Dr" : bal < 0 ? "Cr" : "";
        return {
            ...row,
            // id: index + 1, // Add serial number
            debit: debit.toFixed(2),
            credit: credit.toFixed(2),
            Balance: Math.abs(bal).toFixed(2),
            extra: extra, // Determine 'Dr' or 'Cr' based on debit or credit
        };
    }),
    grandTotalRow,
] 


  // const formattedRows = [
  //   openingBalRow,
  //   ...ledgerDataResult.map((row, index) => {
  //     const debit = parseFloat(row.DrAmt || 0);
  //     const credit = parseFloat(row.CrAmt || 0);
  //     // console.log(openingBalRow.extra)
  //     bal = debit + bal; // Update balance for the current row
  //     bal = bal - credit; // Update balance for the current row
  //     // console.log(bal)
  //     const extra = bal > 0 ? "Dr" : bal < 0 ? "Cr" : "";
  //     return {
  //       ...row,
  //       // id: index + 1, // Add serial number
  //       debit: debit.toFixed(2),
  //       credit: credit.toFixed(2),
  //       Balance: Math.abs(bal).toFixed(2),
  //       extra: extra, // Determine 'Dr' or 'Cr' based on debit or credit
  //     };
  //   }),
  //   grandTotalRow,
  // ];
  // console.log("formattedRows", formattedRows)
  const rowsWithoutGrandTotal = formattedRows.filter(
    (row) => row.LName !== "Grand Total"
  );
  const rowsWithoutOpeningBal = formattedRows.filter(
    (row) => row.LName !== "Opening Balance"
  );
  const lastIndex = rowsWithoutOpeningBal.length - 2;
  
  // Access the last row using its index
  const lastRow = rowsWithoutOpeningBal[lastIndex];
  // console.log(lastRow)
    // console.log(lastRow.Balance);
    var balance
    if(lastRow){
     balance = lastRow.Balance || 0.00
    }else{
      balance = 0.00
    }

  useEffect(()=>{
    // console.log(balance)
    setTotalBal(balance)
  },[ledgerDataResult])
  let finalRows;
  // console.log(totalCredit)
  if (totalCredit == 0) {
    finalRows = [...rowsWithoutGrandTotal];
  } else {
    finalRows = [...rowsWithoutGrandTotal];
  }

  const handleDownloadAsPDF = () => {
    console.log(formattedRows)
    // Define table headers
    const headers = columns
    .filter(
      (column) => column.Header !== "S No" && column.accessor !== "VouNo" && column.accessor !== "Vtype" && column.accessor !== "DpAbbr"
    ) // Exclude the "S No", "No", and "Vtype" columns
    .map((column) => column.Header);

    columns.forEach(column => {
      column.align = "right";
    });
    // Define table data
    const data = formattedRows.map((row) => {
      // console.log(row);
      return headers.map((header) => {
        switch (header) {
          case "No":
            return row.VouNo;
          case "Date":
            return formatDate(row.VDate);
          case "Particulars":
            return row.LName;
            case "Debit":
              return parseFloat(row.DrAmt).toFixed(2); // Format Debit to 2 decimal places and convert to string
            case "Credit":
              return parseFloat(row.CrAmt).toFixed(2); // Format Credit to 2 decimal places and convert to string
            case "Balance":
              return row.Balance;
          case "Remark":
            return row.Remark1;
          case "Extra":
            return row.extra;
          default:
            return row[header];
        }
      });
    });
  
    const doc = new jsPDF({
      orientation: "portrait",
    });

    const companyName = localStorage.getItem("cName");
    const companyLocation = localStorage.getItem("selectedLocation");

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${day}-${month}-${year}`;

    const endDateYear = endDate.getFullYear();
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const endDateDay = String(endDate.getDate()).padStart(2, "0");

    // Concatenate the components in DD-MM-YYYY format for the endDate
    const formattedEndDate = `${endDateDay}-${endDateMonth}-${endDateYear}`;

    doc.setFont("helvetica", "bold"); // Set the font to bold variant of Helvetica
    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed

    doc.setFontSize(16);
    doc.text(`${companyName} ${companyLocation}`, center, 20, { align: "center" });


    doc.setFontSize(12);
    doc.text(dropdown2Value, center, 28, { align: "center" });
    doc.text(`${formattedStartDate} to ${formattedEndDate}`, center, 34, { align: "center" });

    // const logoImg = new Image();
    //   logoImg.src = logo; // Replace 'path/to/your/logo.png' with the actual path to your logo image
    //   doc.addImage(logoImg, 'PNG', 10, 9, 30, 20);

    // Add a line below the date range
    doc.setLineWidth(0.5);
    doc.line(center - 30, 22, center + 30, 22);
    const quarterHeight = doc.internal.pageSize.height / 6;

// Add table to PDF
doc.autoTable({
  startY: quarterHeight,
  head: [headers],
  body: data,
  didParseCell: (data) => {
    const row = data.row.index;
    const column = data.column.dataKey;
    if (column === 4 || column ===  2 || column === 3 ) {
      data.cell.styles.halign = 'right'; // Align Debit, Credit, and Balance columns to the right
    }

  if (row % 2 !== 0) { // Check if row index is odd
      data.cell.styles.fillColor = "#4FA9A7";
      data.cell.styles.textColor = "#ffffff";
    }
    if (row % 2 === 0) { // Check if row index is even
      // data.table.columns.styles.halign = "right"; // Apply background color to even rows
    }
  }
});

// // Save PDF
//     const pdfBlob = doc.output('blob');
//     const pdfUrl = URL.createObjectURL(pdfBlob);

//     // Log the generated PDF link to console
//     console.log("Generated PDF link:", pdfUrl);

//     // Optionally, you can open the PDF in a new tab
    // window.open(pdfUrl, '_blank');

//     // Clean up URL object after use
//     URL.revokeObjectURL(pdfUrl);

    // Save PDF
    doc.save("Ledger.pdf");
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Return empty string if date is invalid

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDownloadAsExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Ledger");
    XLSX.writeFile(wb, "Ledger.xlsx");
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent default form submission
      handleSearch(); // Trigger search when Enter key is pressed
    }
  };
  // console.log("Balance",openingBal)
  // Inside your functional component
  const handleStartDateChange = (date) => {
    setLedgerDataResult([]);
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setLedgerDataResult([]);
    setEndDate(date);
  };
  // console.log(totalBal)
  return (
    <div className="">
      {showLoader && <Loader />}
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        LEDGER
      </h1>
      <div onKeyDown={handleKeyDown}>
        <div className="flex flex-col lg:flex-row items-start justify-start p-2 space-y-2 lg:space-y-0 lg:space-x-1">
          {/* Party Name Dropdown */}
          <DropDown
            id="dropdown2"
            value={dropdown2Value}
            onChange={handleDropdownChange}
            options={parties}
            label="Party Name"
          />

          <div className="flex flex-row space-x-1 w-full">
            {/* Start Date Picker */}
            <CustomDatePicker
              id="startDate"
              selected={startDate}
              onChange={handleStartDateChange}
              className="p-1 border rounded-md outline-none w-full"
              label="Start Date"
            />

            {/* End Date Picker */}
            <CustomDatePicker
              id="endDate"
              selected={endDate}
              onChange={handleEndDateChange}
              className="p-1 border rounded-md outline-none w-full"
              label="End Date"
            />
          </div>

          {/* Find Button */}
          <CommonButton onClick={handleSearch} />
          </div>
          </div>
          {/* <DataTable rows={finalRows} columns={columns} /> */}
          <DynamicTable columns={columns} rows={finalRows} totalBal={totalBal}/>
          <DownloadData onPdfClick={handleDownloadAsPDF}/>
    </div>
  );
};

export default Ledger;
