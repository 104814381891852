import React, { useEffect, useState } from 'react';
import DropDown from '../Common/Dropdown';
import CustomDatePicker from '../Common/DatePicker';
import CommonButton from '../Common/FindButton';
import DataTable from '../Common/DataTable';
import DownloadData from '../Common/DownloadData';
import Loader from '../Common/Loader';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx'; // Import xlsx library
import axios from 'axios'; // Import Axios
import Cookies from 'js-cookie';

const CreditorList = () => {
    const [dropdown2Value, setDropdown2Value] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [showLoader, setShowLoader] = useState(false);
    const [rows , setRows] = useState([])
    const [formattedRows , setFormattedRows] = useState([])

    let creditorsCode = 1

    const columns = [
        { field: 'id', headerName: 'S No', width: 100, align: 'center', headerAlign: 'center' },
        { field: 'Particulars', headerName: 'Particular/Name', width: 350, align: 'left', headerAlign: 'left' },
        { field: 'Amount', headerName: 'Amount', width: 150, align: 'right', headerAlign: 'right' },
        { field: 'extra', headerName: '', width: 50, headerAlign: 'center', align: 'center' },
    ];

    // Calculate total amount
    const totalAmount = rows.reduce((total, row) => total + parseFloat(row.Amount), 0);

    // Update the rows state with formatted rows whenever the underlying data changes
    useEffect(() => {
        const formattedRows = [
            ...rows.map(row => ({
                ...row,
                Amount: Math.abs(parseFloat(row.Amount)).toFixed(2),
                extra: parseFloat(row.Amount) > 0 ? 'Dr' : 'Cr' // Display 'Dr' if Amount > 0, otherwise 'Cr'
            })),
            { id: '', Particulars: 'Grand Total', Amount: totalAmount.toFixed(2), extra: '' }
        ];
        setFormattedRows(formattedRows); // Assuming you have a state variable for formattedRows
    }, [rows, totalAmount]);


    const filter = [
        { index:"", value: 'all', label: 'Choose an option', disabled: true },
        { index: 0, value: 'agentWise', label: 'AgentWise' },
        { index: 1, value: 'partyWise', label: 'PartyWise' },
        { index: 2, value: 'cityWise', label: 'CityWise' },
        { index: 3, value: 'stateWise', label: 'StateWise' },
    ];
    

      const handleSearch = async() => {
        setShowLoader(true);

        // Get the year, month, and day components from the startDate
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const day = String(startDate.getDate()).padStart(2, '0');

        // Concatenate the components in DD-MM-YYYY format for the startDate
        const formattedStartDate = `${year}${month}${day}`;

        const selectedIndex = filter.findIndex(option => option.value === dropdown2Value) - 1;

        // Log dropdown values when the "Find" button is clicked
        // console.log("Debtors Code:", debtorsCode);
        // console.log("Selected Index:", selectedIndex);
        // console.log("Date:",formattedStartDate);

        const requestBody = {
            DebtorIndex: creditorsCode, // Assuming you have the DebtorIndex value
            SelectedIndex: selectedIndex, // Assuming you have the selectedIndex value
            StartDate: formattedStartDate, // Assuming you have the startDate value
          };
        // API integration with Axios
        const token = Cookies.get('token'); // Retrieve token from cookies
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'token': token
            }
          };
        const apiUrl = 'http://localhost:8080/debtorsList';
        const response = await axios.post(apiUrl, requestBody, config);
        const AllDebtors = response.data.AllDebtors
        // console.log(AllDebtors)
        setRows(AllDebtors)
        setTimeout(() => {
            setShowLoader(false);
          }, 1000);
    };

    const handleDownloadAsPDF = () => {
        // Initialize jsPDF
        const doc = new jsPDF();

        // Define table headers
        const headers = columns.map(column => column.headerName);

        // Define table data
        const data = rows.map(row => Object.values(row));

        const companyName = localStorage.getItem("cName")
        const companyLocation = localStorage.getItem("selectedLocation")

        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const day = String(startDate.getDate()).padStart(2, '0');
        const formattedStartDate = `${day}-${month}-${year}`;
        
        const center = doc.internal.pageSize.width / 2;
        doc.setFontSize(14);
        doc.text(`${companyName} , ${companyLocation}`, center, 7, { align: "center" });
        // Add the date range below Alifa International, Mumbai
        doc.setFontSize(10);
        doc.text(`Date : ${formattedStartDate}`, center, 12, { align: "center" });
        
        // Add a line below the date range
        doc.setLineWidth(0.5);
        doc.line(center - 30, 22, center + 30, 22);
        // Add table to PDF
        doc.autoTable({ head: [headers], body: data });

        // Save PDF
        doc.save('Debtor_List.pdf');
    };

    const handleDownloadAsExcel = () => {
        const sheet = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet, 'Debtor_List');
        XLSX.writeFile(wb, 'Debtor_List.xlsx');
    };
    const handleKeyDown = (e) => {
        if (e.key === ' ') {
          e.preventDefault(); // Prevent default form submission
          handleSearch(); // Trigger search when Enter key is pressed
        }
      };

      const handleDropdown2Change = (e) => {
        setDropdown2Value(e.target.value);
        setFormattedRows([])
      };
      const handleStartDateChange = (date) => {
        setStartDate(date);
        setFormattedRows([])
      };
    return (
        <div className=''>
        {showLoader && (
                <Loader/>
        )}
        <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        CREDITORS LIST
            </h1>
        <div onKeyDown={handleKeyDown}>
            <div className="flex flex-col lg:flex-row items-start justify-start p-2 space-y-2 lg:space-y-0 lg:space-x-1">
                <DropDown
                    id="dropdown2"
                    value={dropdown2Value}
                    onChange={handleDropdown2Change}
                    options={filter}
                    label="Filter"
                />
                <CustomDatePicker
                    id="startDate"
                    selected={startDate}
                    onChange={handleStartDateChange}
                    className="p-1 border rounded-md outline-none w-full"
                    label="Select Date"
                />
                <CommonButton onClick={handleSearch}/>
            </div>
        </div>
            <DataTable rows={formattedRows} columns={columns} />
            
        </div>
    );
};

export default CreditorList;
