import React, { useEffect, useState } from "react";
import DropDown from "../Common/Dropdown";
import CustomDatePicker from "../Common/DatePicker";
import CommonButton from "../Common/FindButton";
import DownloadData from "../Common/DownloadData";
import Loader from "../Common/Loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import axios from "axios";
import Cookies from "js-cookie";
import { api, api1, api2 } from "../Common/apiURL";
import DynamicTable from "../Common/NewDataTable";
import { useNavigate } from "react-router-dom";

const DebtorList = () => {
  const [dropdown2Value, setDropdown2Value] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [formattedRows, setFormattedRows] = useState([]);
  const navigate = useNavigate();
  let debtorsCode = 0;

  const columns = [
    {
      Header: "S No",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.index + 1}</div>
      ),
    },
    {
      accessor: "Particulars",
      Header: "Particular/Name",
      width: 350,
      align: "left",
      headerAlign: "center",
    },
    {
      accessor: "Amount",
      Header: "Amount",
      width: 150,
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{value}</div>
      ), // Align cell content to the right
    },
    {
      accessor: "extra",
      Header: "",
      width: 50,
      headerAlign: "center",
      align: "center",
    },
  ];

  var totalAmount;
  if (rows === undefined) {
    totalAmount = 0;
  } else {
    totalAmount = rows.reduce(
      (total, row) => total + parseFloat(row.Amount),
      0
    );
  }

  useEffect(() => {
    const formattedRows = [
      ...rows.map((row) => ({
        ...row,
        Amount: Math.abs(parseFloat(row.Amount)).toFixed(2),
        extra: parseFloat(row.Amount) > 0 ? "Dr" : "Cr",  
      })),
    ];
    setFormattedRows(formattedRows);  
  }, [rows, totalAmount]);

  const filter = [
    { index: "", value: "", label: "Choose an option", disabled: true },
    { index: 0, value: "AgentWise", label: "AgentWise" },
    { index: 1, value: "PartyWise", label: "PartyWise" },
    { index: 2, value: "CityWise", label: "CityWise" },
    { index: 3, value: "StateWise", label: "StateWise" },
  ];

  const handleSearch = async () => {
    setShowLoader(true);
    if (!dropdown2Value || !startDate) {
      alert('Please Select an option from Filter Dropdown.')
      setShowLoader(false);
      return;
    }
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;
    const selectedIndex =
      filter.findIndex((option) => option.value === dropdown2Value) - 1;

    const requestBody = {
      DebtorIndex: debtorsCode,
      SelectedIndex: selectedIndex,
      StartDate: formattedStartDate,
    };
    const token = Cookies.get("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    };
    const BASE_URL = api2;
    try {
      const response = await axios.post(
        `${BASE_URL}/debtorsList`,
        requestBody,
        config
      );
      const AllDebtors = response.data.AllDebtors;
      setRows(AllDebtors);
    } catch (error) {
      if(error.response.status){
        alert('Please Login Again!!!')
        navigate('/')
      }
    } finally {
      setShowLoader(false);
    }
  };

  const handleDownloadAsPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Define table headers
    const headers = columns.map((column) => column.headerName);

    // Define table data
    let data = rows.map((row, index) => {
      // Increment index to start from 1
      index++;

      // Create a new array with serial number added at the beginning
      return [index, ...Object.values(row)];
    });

    // Add the "Grand Total" row separately
    data.push(["", "Grand Total", totalAmount.toFixed(2), ""]);

    console.log(data);

    const companyName = localStorage.getItem("cName");
    const companyLocation = localStorage.getItem("selectedLocation");

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${day}-${month}-${year}`;

    const center = doc.internal.pageSize.width / 2;
    doc.setFontSize(14);
    doc.text(`${companyName} , ${companyLocation}`, center, 7, {
      align: "center",
    });
    // Add the date range below Alifa International, Mumbai
    doc.setFontSize(10);
    doc.text(`Date : ${formattedStartDate}`, center, 12, { align: "center" });

    // Add a line below the date range
    doc.setLineWidth(0.5);
    doc.line(center - 30, 22, center + 30, 22);
    // Add table to PDF
    doc.autoTable({ head: [headers], body: data });

    // Save PDF
    doc.save("Debtor_List.pdf");
  };

  const handleDownloadAsExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(formattedRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Debtor_List");
    XLSX.writeFile(wb, "Debtor_List.xlsx");
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent default form submission
      handleSearch(); // Trigger search when Enter key is pressed
    }
  };

  const handleDropdown2Change = (e) => {
    setDropdown2Value(e.target.value);
    setFormattedRows([]);
  };

  const handleStartDateChange = (date) => {
    console.log(date)
    setStartDate(date);
    setFormattedRows([]);
  };

  return (
    <div className="">
      {showLoader && <Loader />}
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
        DEBTORS LIST
      </h1>
      <div onKeyDown={handleKeyDown}>
      <div className="grid grid-cols-3 gap-4">
      <div className="col-span-1">
        <DropDown
          id="dropdown2"
          value={dropdown2Value}
          onChange={handleDropdown2Change}
          options={filter}
          label="Filter"
          className="p-1 border rounded-md outline-none w-full z-0"
        />
      </div>
      <div className="col-span-1">
        <CustomDatePicker
          id="startDate"
          selected={startDate}
          onChange={handleStartDateChange}
          className="p-1 border rounded-md outline-none w-full z-0"
          label="Select Date"
        />
      </div>
      <div className="col-span-1">
        <CommonButton onClick={handleSearch} />
      </div>
    </div>
    
      </div>
      {formattedRows.length > 1 ? (
        <DynamicTable columns={columns} rows={formattedRows} />
      ) : (
        <DynamicTable columns={columns} rows={rows} />
      )}
    </div>
  );
};

export default DebtorList;
