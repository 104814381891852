// Dashboard.jsx
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import SaleRegister1 from './Sales-Register-1';
import DefaultPage from './Default';
import StockSummary from './StockSummary';
import DispatchReport from './DispatchReport';
import ReceivableBills from './ReceivableBills';
import Ledger from './Ledger';
import DebtorList from './DebtorList';
import CreditorList from './CreditorList';
import CreditorsReceivableBills from './CreditorsBills';
import Invoice from './Invoice';
import InvoiceDetails from './Invoice';
import SaleOrder from './SaleOrder';
import BillPermission from './BillPermission';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyForm from './MyForm';
import EnquiryForm from './EnquiryForm';
import StockReportDPWise from './Stock-Report-Depowise';

const Content = () => {
  const { subPage } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [subPage]);

  const renderContent = () => {
    switch (subPage) {
      case 'sales-register':
        return <SaleRegister1 />;
      case 'stock-summary':
        return <StockSummary />;
        // return <StockReportDPWise />;
      case 'dispatch-report':
        return <DispatchReport/>
      case 'bills-outstanding-[receivable]':
        return <ReceivableBills/>
      case 'bills-outstanding-[payable]':
        return <CreditorsReceivableBills/>
      case 'ledger':
        return <Ledger/>
      case 'debtors-list':
        return <DebtorList/>
      case 'creditors-list':
        return <CreditorList/>
      case 'purchase-register':
        return <InvoiceDetails/>
      case 'billing-permission':
        return <BillPermission/>
        // return <MyForm/>
      case 'sale-order':
        return <SaleOrder/>
      
      default:
        return <DefaultPage />;
    }
  };

  return (
    <>
      <div className=" w-full flex">
        <div className="hidden lg:block lg:w-1/5 h-screen lg:fixed shadow-lg p-1">
          <Sidebar />
        </div>
        <div className="w-full lg:ml-[20%] lg:w-4/5">
          <div className="w-full fixed top-0 left-0 right-0 z-50000 bg-[#4FA9A7] mb-10 md:w-auto lg:ml-[20%] rounded-lg">
          <Header  />
          </div>
          <div className="overflow-y-auto overflow-x-hidden mt-[17%] md:mt-[10%] lg:mt-[6%] h-screen rounded-lg p-1 bg-gray-300">
            {renderContent()}
          </div>
        </div>
      </div>

    </>
  );
};

export default Content;
