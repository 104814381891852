// DynamicTable.js
import React from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Importing icons from react-icons

const DynamicTable = ({ columns, rows, totalBal }) => {
  // console.log(rows)
  // Use React Table's useTable hook to create table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: rows,
      initialState: { pageIndex: 0, pageSize: 50 }, // Set initial page index to 0 and page size to 100
    },
    useFilters,
    useSortBy,
    usePagination
  );
  // console.log(rows)
  

  let sumDr = 0;
  let sumCr = 0;

  rows.forEach(entry => {
      if (entry.extra === 'Dr') {
          sumDr += parseFloat(entry.Amount);
      } else if (entry.extra === 'Cr') {
          sumCr += parseFloat(entry.Amount);
      }
  });



  const grandTotal = (sumDr - sumCr).toFixed(2);

      // console.log(grandTotal)

      const totalCreditAmt =
          rows.length > 0
            ? parseFloat(
                rows.reduce(
                  (acc, row) =>
                    acc +
                    parseFloat(
                        row.CrAmt ||
                        0
                    ),
                  0
                )
              )
            : 0;

            const totalDebitAmt =
            rows.length > 0
              ? parseFloat(
                  rows.reduce(
                    (acc, row) =>
                      acc +
                      parseFloat(
                          row.DrAmt ||
                          0
                      ),
                    0
                  )
                )
              : 0;

              const totalTaxable =
            rows.length > 0
              ? parseFloat(
                  rows.reduce(
                    (acc, row) =>
                      acc +
                      parseFloat(
                          row.TaxableValue ||
                          0
                      ),
                    0
                  )
                )
              : 0;

              const totalSaleAmt =
            rows.length > 0
              ? parseFloat(
                  rows.reduce(
                    (acc, row) =>
                      acc +
                      parseFloat(
                          row.SaleAmt ||
                          0
                      ),
                    0
                  )
                )
              : 0;

              const totalBillAmt =
            rows.length > 0
              ? parseFloat(
                  rows.reduce(
                    (acc, row) =>
                      acc +
                      parseFloat(
                          row.BillAmt ||
                          0
                      ),
                    0
                  )
                )
              : 0;

  // const grandTotalFormatted = grandTotal.toLocaleString("en-IN", {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // });

  const grandtotalCreditAmt = totalCreditAmt.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const grandtotalDebitAmt = totalDebitAmt.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const grandtotalTaxable = totalTaxable.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const grandtotalSaleAmt = totalSaleAmt.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const grandtotalBillAmt = totalBillAmt.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const grandLastBalance = Number(totalBal).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // console.log(grandTotal); // Check the type of the formatted number
  rows
    .filter(row => row.id) // Filter out rows with truthy id value
    .map((row) => {
      // Check if the row meets a condition (for example, if the id is equal to a specific value)
      const isRedBackgroundRow = row.id === "specificId"; // Change "specificId" to the id you want to match
      // Log the row with the red background color if it meets the condition
    });
  
  // Check the type of the formatted number

  return (
    <div className="p-1">
      <div className=" h-[420px] rounded-lg  overflow-x-auto">
        <div className="">
          <table {...getTableProps()} className="w-full">
            <thead className="bg-[#4EA8B7] text-white font-semibold">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-3 py-2"
                    >
                      {column.render("Header")}
                      {/* Add sort icon */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ maxHeight: "300px", overflowY: "scroll" }}
            >
              {page.map((row, index) => {
                // Access the 'page' variable here
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={index % 2 === 0 ? "bg-white" : "bg-blue-200"}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className="px-2 py-1 border">
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {rows.length > 0  && (rows[0].PDFFileName || rows[0].Particulars || rows[0].LName == "Opening Balance" || rows[0].TaxableValue || rows[0].BillAmt)   && (
        <div className="grid grid-cols-2 justify-end p-1 text-black rounded-lg text-white">
          <div className="flex justify-end text-black text-xl mt-5">
          </div>
          {rows.length > 0 && rows[0].Amount  && (
            <div className="flex justify-end text-black">
              <strong className="text-md font-bold">Total Amount : {grandTotal}</strong>
            </div>
          )}
          {rows.length > 0 && rows[0].TaxableValue  && (
            <div className="flex justify-end text-black">
              <strong>Total Taxable Amount : {grandtotalTaxable}</strong>
            </div>
          )}
          {rows.length > 0 && rows[0].DrAmt && (
            <div className="grid gap-1">
            <div className="flex justify-end text-black">
              <strong>Total Debit : {grandtotalDebitAmt}</strong>
            </div>
            <div className="flex justify-end text-black">
              <strong>Total Credit : {grandtotalCreditAmt}</strong>
            </div>
            <div className="flex justify-end text-black">
              <strong>Last Balance : {grandLastBalance}</strong>
            </div>
            </div>
          )}
          {rows.length > 0 && rows[0].BillAmt && (
            <div className="grid gap-1">
            <div className="flex justify-end text-black">
              <strong>Total Sale : {grandtotalSaleAmt}</strong>
            </div>
            <div className="flex justify-end text-black">
              <strong>Total Bill : {grandtotalBillAmt}</strong>
            </div>
            </div>
          )}
        </div>
      )}
      <div className="bg-[#4EA8B7] w-full p-2 flex justify-between rounded-lg">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="bg-blue-500 rounded-md px-2 py-1"
        >
          <FaArrowLeft color="white" /> {/* Left arrow icon */}
        </button>
        <span className="text-white">
          Page{" "}
          <strong>
            {pageIndex + 1} of {Math.ceil(rows.length / pageSize)}
          </strong>{" "}
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="bg-blue-700 rounded-md px-2 py-1"
        >
          <FaArrowRight color="white" /> {/* Right arrow icon */}
        </button>
      </div>
    </div>
  );
};

export default DynamicTable;
