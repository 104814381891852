import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import './datagrid.css'; 

const CustomDatePicker = ({ id, selected, onChange, className, label }) => {
  return (
    <div className="flex flex-col items-start space-x-1 w-full">
      <label htmlFor={id} className="text-md text-black font-semibold mb-1">{label}</label>
      <DatePicker
        id={id}
        selected={selected}
        onChange={onChange}
        className={className}
        dateFormat="dd-MM-yyyy"
      />
    </div>
  );
};

export default CustomDatePicker;
