// import React from 'react';

// const DropDown = ({ id, value = '', onChange, options, label }) => {
//   return (
//     <div className="flex flex-col items-start space-x-1 w-full p-1">
//       <label key={id} htmlFor={id} className="text-md text-black font-semibold mb-1">{label}</label>
//       <select
//         id={id}
//         value={value}
//         onChange={onChange}
//         className="p-1 border rounded-md outline-none w-full"
//       >
//       {options.map(option => (
//         <option key={option.id} value={option.value}>
//           {option.label}
//         </option>
//       ))}
//       </select>
//     </div>
//   );
// }

// export default DropDown;


import React from 'react';

const DropDown = ({ id, value , onChange, options, label }) => {
  return (
    <div className="flex flex-col items-start space-x-1 w-full p-1">
      <label key={id} htmlFor={id} className="text-md text-black font-semibold mb-1">{label}</label>
      <input
        list={`${id}-list`}
        id={id}
        value={value}
        onChange={onChange}
        className="p-1 border rounded-md outline-none w-full"
      />
      <datalist id={`${id}-list`}>
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </datalist>
    </div>
  );
}

export default DropDown;

