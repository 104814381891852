// Content.js
import React, { useEffect } from 'react';
import { IoMdAnalytics } from 'react-icons/io';
import { FaRegChartBar } from 'react-icons/fa';
import { TiChartPie } from 'react-icons/ti';
import { BsGraphUp } from 'react-icons/bs';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DefaultPage = () => {
  // useEffect(() => {
  //   toast.success("Welcome To HomePage")
  // }, []);
  return (
    <div >
      <h1 className="font-bold text-xl text-black p-2 w-full text-center">
      </h1>
    </div>
  );
};

export default DefaultPage;
